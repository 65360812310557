import { PropsWithChildren } from 'react';
import { Checkbox } from './checkbox-icon';
import styles from './checkbox.module.scss';



export interface CheckProps extends PropsWithChildren<React.InputHTMLAttributes<HTMLInputElement>> {
}

export const Check = (props: CheckProps) => {
  const {children, ...inputProps} = props;
  const defaultClass = styles.label;
  const className = props.className ? `${props.className} ${defaultClass}` : defaultClass;
  return (
    <label  className={className}>
      <input {...inputProps} type='checkbox' className={styles.check} />
      <Checkbox className={styles.checkbox}/>
      {children}
    </label>
  );
};
