import { PreDefinedQuery } from './pre-defined-queries/base';
import { GetCheckoutSession } from './pre-defined-queries/get-checkout-session';

export class CheckoutClient {
  async exec<T extends PreDefinedQuery<unknown>>(
    query: T,
  ): Promise<ReturnType<T['exec']>>
  {

    return query.exec() as ReturnType<T["exec"]>;
  }
}

export const client = new CheckoutClient();

