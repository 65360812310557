import { Salon, SalonSearch } from '@/types/salon';
import { PreDefinedQuery } from './base';

type Input = SalonSearch;

type Result = {
  size: number;
  skipped: number;
  next: string;
  count: number;
  entities: Salon[];
} | undefined;

export class GetSalonPage extends PreDefinedQuery<Result> {
  constructor(public variables: Input) {
    super();
  }
  public readonly query: string = `
    query Salons($size: Int!, $next: String, $country: String, $hair: Boolean, $skin: Boolean, $color: Boolean, $makeup: Boolean, $lat: Float, $long: Float, $radius: Int, $random: Boolean) {
      salons(size: $size, next: $next, country: $country, hair: $hair, skin: $skin, color: $color, makeup: $makeup, lat: $lat, long: $long, radius: $radius, random: $random) {
        size
        skipped
        next
        count
        entities {
          salon_id
          name
          distance

          filter {
            hair
            skin
            color
            makeup
          }
          info {
            headline
            description
            opening_hours
          }
          owner {
            salutation
            firstname
            lastname
          }
          location {
            address
            postcode
            city
            country
            coordinates {
              lat
              long
            }
          }
          flags {
            sfb
            hr_platform
            climate_neutral_salon
            salon_comission
            top_employer
            premium_salon
          }
          contact {
            phone
            website
            email
            social {
              type
              name
            }
          }
          images {
            id
            b64(preset: "thumb-100-fit-b64-webp")
            srcset(preset: "salon")
          }
        }
      }
    }
`;

  protected transformResponse(responseData?: { data?: { salons: Result }}): Result {
    if (!responseData?.data?.salons) {
      console.error('Failed to fetch salons');
    }
    return responseData?.data?.salons;
  }
}
