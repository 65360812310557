import { BaseUpdateSession } from './base-update-session';

type Input = {
  sessionId: string;
  email: string;
}
export class ResetCustomerPassword extends BaseUpdateSession {
  constructor(public variables: Input) {
    super()
  }
  public readonly query: string = `
    mutation Mutation($sessionId: String!, $email: String!) {
      checkout {
        resetCustomerPassword(sessionId: $sessionId, email: $email) {
          config {
            nonce
          }
        }
      }
    }
  `;

  protected transformResponse(responseData: any) {
    return super.transformResponse(responseData, 'resetCustomerPassword')
  }
}