import { ITransactionType } from './base';

export enum AddressType {
  HOME = 'home',
  POST_OFFICE = 'post_office',
  PARCEL_BOX = 'parcel_box'
}

export interface IAddress {
  prefix?: string;
  firstname: string;
  lastname: string;
  addr1: string;
  addr2?: string;
  addr3?: string;
  postcode: string;
  city: string;
  country: string;
  addressType?: AddressType;
}

export interface IAddressesTransaction extends ITransactionType {
  billing: IAddress;
  shipping: IAddress;
  setAsDefault?: { billing?: boolean, shipping?: boolean }
}