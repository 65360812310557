import { ICheckoutSession } from '@lws/lib-checkout';
import { Heading } from '../ui/layout/heading';
import styles from './item.module.scss';
import { Price } from '../ui/price';
import { WithTranslation, withTranslation } from 'react-i18next';

type ItemType = ICheckoutSession['quote']['items'][number]

type ItemProps = {
  item: ItemType;
}

const additionalAttribute = (item: ItemType, key: string) => {
  return item.additionalAttributes.find(i => i.key === key)?.value;
};

export const Item = withTranslation('common')((props: ItemProps & WithTranslation) => {
  const { t, item } = props;
  const fillingQuantity = additionalAttribute(item, 'filling_quantity');
  const identNumber = additionalAttribute(item, 'labio_identnumber');
  const basePrice = additionalAttribute(item, 'base_price');
  const image = additionalAttribute(item, 'image_url');
  return (
    <li className={styles.item}>
      <a href={item.additionalAttributes.find(({key}) => key === 'product_url')?.value } target='_blank'>
        {image ? (<img src={image} alt={item.name} />) : ''}
        <div>
          <Heading level={4} title={item.name} />
          {fillingQuantity ? <p className={styles.smaller}>{t('checkout.review.items.filling_quantity')} {fillingQuantity}</p> : ''}
          <p className={styles.smaller}>{t('checkout.review.items.amount')} {item.qty}</p>
          {identNumber || basePrice ? (
            <p className={`${styles.infos} ${styles.smaller}`}>
              {identNumber ? <span>{t('checkout.review.items.sku')} {identNumber}</span> : ''}
              {identNumber && basePrice ? ', ' : ''}
              {basePrice ? <span>{basePrice}</span> : ''}
            </p>
          ) : ''}
          {<p className={styles.price}><Price price = {item.price} /></p>}
        </div>
      </a>
    </li>
  );
});
