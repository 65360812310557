import { IAddress } from '@/types';
import { BaseUpdateSession, UpdateSessionResult } from './base-update-session';

type Input = {
  sessionId: string;
  billing: IAddress;
  shipping: IAddress;
}
export class SetAddresses extends BaseUpdateSession {
  constructor(public variables: Input) {
    super()
  }
  public readonly query: string = `
    mutation Mutation($sessionId: String!, $billing: AddressInput!, $shipping: AddressInput!) {
      checkout {
        setAddresses(sessionId: $sessionId, billing: $billing, shipping: $shipping) {
          ${this.getPartialSessionResponse()}
        }
      }
    }
  `;

  protected transformResponse(responseData: any) {
    return super.transformResponse(responseData, 'setAddresses')
  }
}