export interface ReviewIconProps extends React.SVGAttributes<SVGSVGElement>{
}


export const ReviewIcon = (props: ReviewIconProps) => {
  return (
    <svg {...props} id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
      <path d="M13.62,87.38h27.57c1.37,0,2.48-1.11,2.48-2.48s-1.11-2.48-2.48-2.48H13.62c-1.37,0-2.48,1.11-2.48,2.48s1.11,2.48,2.48,2.48Z"/>
      <path d="M41.19,33.43H13.62c-1.37,0-2.48,1.11-2.48,2.48s1.11,2.48,2.48,2.48h27.57c1.37,0,2.48-1.11,2.48-2.48s-1.11-2.48-2.48-2.48Z"/>
      <path d="M13.62,75.13h24.11c1.37,0,2.48-1.11,2.48-2.48s-1.11-2.48-2.48-2.48H13.62c-1.37,0-2.48,1.11-2.48,2.48s1.11,2.48,2.48,2.48Z"/>
      <path d="M13.62,50.63h24.11c1.37,0,2.48-1.11,2.48-2.48s-1.11-2.48-2.48-2.48H13.62c-1.37,0-2.48,1.11-2.48,2.48s1.11,2.48,2.48,2.48Z"/>
      <path d="M13.62,62.88h20.64c1.37,0,2.48-1.11,2.48-2.48s-1.11-2.48-2.48-2.48H13.62c-1.37,0-2.48,1.11-2.48,2.48s1.11,2.48,2.48,2.48Z"/>
      <path d="M79.1,90.37c0-1.37-1.11-2.48-2.48-2.48s-2.48,1.11-2.48,2.48v.37c.01,1.27-.02,2.29-.61,2.88-.59.6-1.91.93-3.71.93H9.92c-2.54,0-4.46-1.8-4.46-4.18V25.72h12.36c2.12,0,8.59,0,8.59-7.47V5.45h43.74c2.81,0,3.9,1.09,3.9,3.9v29.82c0,1.37,1.11,2.48,2.48,2.48s2.48-1.11,2.48-2.48V9.35c0-5.54-3.31-8.85-8.85-8.85H24.43c-.9,0-1.68.48-2.11,1.2-.03.03-.06.04-.09.07L1.56,21.46c-.25.24-.45.53-.58.85-.29.41-.47.9-.47,1.44v66.63c0,5.12,4.13,9.13,9.41,9.13h59.91c3.26,0,5.63-.78,7.23-2.4,2.08-2.1,2.06-4.91,2.05-6.41v-.32ZM21.46,9.33v8.92c0,1.68,0,2.52-3.64,2.52h-8.37l12.01-11.44Z"/>
      <path d="M98.76,95.27l-16.93-16.92c2.95-3.69,4.72-8.36,4.72-13.44,0-11.89-9.68-21.57-21.57-21.57s-21.57,9.68-21.57,21.57,9.67,21.57,21.57,21.57c5.03,0,9.66-1.74,13.34-4.64l16.94,16.93c.48.48,1.12.72,1.75.72s1.27-.24,1.75-.73c.97-.97.97-2.53,0-3.5ZM64.99,81.53c-9.16,0-16.62-7.45-16.62-16.62s7.46-16.62,16.62-16.62,16.62,7.45,16.62,16.62-7.46,16.62-16.62,16.62Z"/>
    </svg>
  );
};
