import { BaseCheckoutAction } from '../action';
import { ICheckoutSession } from '../types';
import { ICheckoutConfig } from '../types/session/config';
import { PreDefinedQuery } from './base';

export type UpdateSessionResult = {
  session: ICheckoutSession,
  actions: Array<BaseCheckoutAction>,
  config: ICheckoutConfig
}

export abstract class BaseUpdateSession extends PreDefinedQuery<UpdateSessionResult | undefined> {
  protected transformResponse(responseData: { data?: { checkout: Record<string, unknown> } }, key?: string): UpdateSessionResult | undefined {
    if(!key || !responseData?.data?.checkout || !(key in responseData.data.checkout) || responseData.data.checkout[key] === undefined) return undefined;
    const session: ICheckoutSession = (responseData.data.checkout[key!] as UpdateSessionResult).session;
    const actions: Array<BaseCheckoutAction> = (responseData.data.checkout[key!] as UpdateSessionResult).actions || [];
    const config: ICheckoutConfig = (responseData.data.checkout[key!] as UpdateSessionResult).config;
    return { session, actions, config };
  }
}
