import { PropsWithChildren } from 'react';
import styles from './box.module.scss';

export interface BoxProps extends PropsWithChildren<React.HTMLAttributes<HTMLDivElement>> {
}

export const Box = (props: BoxProps) => {
  const defaultClasses = styles['box'];
  const className = props.className ? `${props.className} ${defaultClasses}` : defaultClasses;
  return (
    <div {...props} className={className}>
      {props.children}
    </div>
  );
};
