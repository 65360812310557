import { AddressType, IAddress, Page, sessionStore } from '@lws/lib-checkout';
import { AddressLayout } from '../components/address';
import { useMemo, useState } from 'react';
import { AddressSelection } from '../components/address/address-selection';
import { AddressCreation } from '@/components/address/address-creation';
import { addressFormPrefixedFormData } from '@/util/address-from-prefixed-form-data';
import { compareAddresses } from '@/util/compare-address';
import styles from './address.module.scss';
import { useFieldValidator, Validated, useValidationContext } from '@lws/react-ui';
import { SubmitButton } from '@/components/ui/form/submit-button';
import { notificationStore } from '@lws/lib-checkout';
import { Box } from '@/components/ui/layout/box';
import { Heading } from '@/components/ui/layout/heading';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Check } from '@/components/ui/form/checkbox';
import { events } from '@lws/lib-track';


export const Address = withTranslation('common')(sessionStore.withPageGuard(Page.ADDRESS, <>NOT ALLOWED</>)((props: WithTranslation) => {
  const { t } = props;
  sessionStore.tracking.useTrack(new events.PwaPageView({
    pageType: 'checkout-address',
    path: '/address',
    referrer: window.document.referrer,
    category: 'checkout',
    title: t('Address'),
  }))
  const { config } = sessionStore.useSession();
  const { customer } =  sessionStore.userInfo.useUserInfo();
  const addresses = sessionStore.address.useAddresses();

  const [ showBilling, setShowBilling ] = useState<boolean>(!!addresses?.shipping && !compareAddresses(addresses.billing, addresses.shipping));

  const [newShipping, setNewShipping ] = useState<boolean>(false);
  const [newBilling, setNewBilling ] = useState<boolean>(false);

  const [currentShippingType, setCurrentShippingType] = useState<AddressType>(AddressType.HOME);

  const billingRequired = useMemo(() => {
    return currentShippingType !== AddressType.HOME;
  }, [currentShippingType]);

  const [ shippingAddresses, billingAddresses ] = useMemo(() => {
    return [
      (customer?.addresses || [])
        .filter(addr => config.allowedCountries.includes(addr.country))
        .filter(addr => !addr.addressType || config.allowedAddressTypes.includes(addr.addressType)),
      (customer?.addresses || []).filter(addr => addr.addressType === AddressType.HOME && config.allowedCountries.includes(addr.country)),
    ];
  }, [ customer ]);


  const setShippingAddressType = (type: AddressType) => {
    if (type !== currentShippingType) setCurrentShippingType(type);
  };

  const { domain: validationDomain } = useValidationContext('address');
  const validator = useFieldValidator(validationDomain);

  const onSubmitForm: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const withBilling = showBilling || billingRequired;
    let shippingAddress: Partial<IAddress> = {};
    if(formData.get('shipping_mode') === 'select') {
      shippingAddress = JSON.parse(formData.get('shipping_selected') as string);

    } else if(formData.get('shipping_mode') === 'input') {
      shippingAddress = addressFormPrefixedFormData(formData, 'shipping');
    }

    let billingAddresses: Partial<IAddress> = {};

    if(withBilling) {
      if(formData.get('billing_mode') === 'select') {
        billingAddresses = JSON.parse(formData.get('billing_selected') as string);

      } else if(formData.get('billing_mode') === 'input') {
        billingAddresses = addressFormPrefixedFormData(formData, 'billing');
      }
    } else billingAddresses = {...shippingAddress};

    if (!validator) {
      console.warn('No validator found for address validation');
      addresses.set(billingAddresses as IAddress, shippingAddress as IAddress);
      return;
    }
    const result = validator?.validate({ shipping: shippingAddress, billing: billingAddresses });

    if (result?.isValid) {
      addresses.set(billingAddresses as IAddress, shippingAddress as IAddress);
    } else {
      // @TODO
      notificationStore.notify({ actions: [{ buttonLabel: 'OK', type: notificationStore.NotificationActionType.TO_SHOP }], message: `Invalid address format: ${result?.issues?.map((issue: { message: string }) => issue.message).join(', ')}` });
    }

  };

  const showNewShipping = !(shippingAddresses.length > 0 && !newShipping && (!addresses.shipping || shippingAddresses.find(addr => compareAddresses(addr, addresses.shipping))));
  const showNewBilling = !(billingAddresses.length > 0 && !newBilling && (!addresses.billing || billingAddresses.find(addr => compareAddresses(addr, addresses.billing))));

  return (
    <>
      <AddressLayout>

        <form onSubmit={onSubmitForm} className={styles['form']}>
          <Validated name='address'>
            <Box>
              <Heading title={t(showNewShipping ? 'checkout.address.new.shipping.headline' : 'checkout.address.shipping.headline')} level={2} style={{border: 0}}/>

              {!showNewShipping ? (
                <>
                  <input type='hidden' name='shipping_mode' value='select' />
                  <AddressSelection
                    namePrefix='shipping'
                    availableAddresses={shippingAddresses}
                    enableNewAddress={() => setNewShipping(true)}
                    setAddressType={setShippingAddressType}
                    currentAddress={addresses.shipping}
                  />
                </>
              ) : (
                <>
                  <input type='hidden' name='shipping_mode' value='input' />
                  <Validated name='shipping'>
                    <AddressCreation
                      namePrefix='shipping'
                      config={config}
                      hasSavedAddresses={shippingAddresses.length > 0}
                      enableSelectSavedAddress={() => setNewShipping(false)}
                      setAddressType={setCurrentShippingType}
                      addressTypes={config.allowedAddressTypes}
                      currentAddress={addresses.shipping}
                    />
                  </Validated>
                </>
              ) }
            </Box>
            <Check className={styles.checkbox} checked={showBilling || billingRequired} onChange={(e) => setShowBilling(e.target.checked)}> {t('checkout.address.checkbox.different_billing_address')}</Check>

            {showBilling || billingRequired ? (
              <Box>
                <Heading title={t(showNewBilling ? 'checkout.address.new.billing.headline' :'checkout.address.billing.headline' )} level={2} style={{border: 0}}/>

                {!showNewBilling ? (
                  <>
                    <input type='hidden' name='billing_mode' value='select' />
                    <AddressSelection
                      namePrefix='billing'
                      availableAddresses={billingAddresses}
                      enableNewAddress={() => setNewBilling(true)}
                      currentAddress={addresses.billing}
                    />
                  </>
                ) : (
                  <>
                    <input type='hidden' name='billing_mode' value='input' />
                    <Validated name='billing'>
                      <AddressCreation
                        namePrefix='billing'
                        hasSavedAddresses={billingAddresses.length > 0}
                        enableSelectSavedAddress={() => setNewBilling(false)}
                        addressTypes={[AddressType.HOME]}
                        config={config}
                        currentAddress={addresses.billing}
                      />
                    </Validated>
                  </>
                ) }
              </Box>
            ) : ''}
          </Validated>
          <div className={styles['button']}>
            <SubmitButton validFor='address' primary type='submit'>{t('checkout.address.button.continue')}</SubmitButton>
          </div>

        </form>
      </AddressLayout>
    </>
  );
}));
