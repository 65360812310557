import { HTMLAttributes } from 'react';

export interface PriceProps extends HTMLAttributes<HTMLSpanElement> {
  price: number
}

export const Price = (props: PriceProps) => {

  const price = (props.price / 100).toLocaleString('de-DE', {
    style: 'currency',
    currency: 'EUR',
  });

  return (
    <span {...props} >{price}</span>
  );
};
