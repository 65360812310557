import { IAddress, sessionStore } from '@lws/lib-checkout';
import { DisplayAddress } from '../ui/address/display';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Heading } from '../ui/layout/heading';
import { Button } from '../ui/layout/button';
import { Icon } from '../ui/icons/icon';
import { useNavigate } from 'react-router-dom';

type Props = {
  title: 'billing' | 'shipping' | 'combined';
  address: IAddress;
}


export const ReviewAddress = withTranslation('common')((props: Props & WithTranslation) => {
  const navigate = useNavigate();
  const { sessionId } = sessionStore.useSession();
  return (
    <>
      <Heading level={3} title={props.t(`checkout.review.address.title.${props.title}`)} button={<Button onClick={() => navigate(`/${sessionId}/address`)} icon={Icon.EDIT}></Button>}/>
      <DisplayAddress address={props.address} />
    </>
  );
});
