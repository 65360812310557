
import { sessionStore } from '@lws/lib-checkout';
import { useRef } from 'react';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import styles from '../review-layout.module.scss';


export const ComputopPayPalButton = () => {
  const { transaction, authorizeTransaction } = sessionStore.payment.usePaymentMethod();
  const payid = useRef();

  const { config } = sessionStore.useSession();

  if(!authorizeTransaction || !authorizeTransaction.redirectUri) return <>Zahlart nicht möglich</>
  const params = (new URL(authorizeTransaction.redirectUri)).searchParams;
  console.log(Object.fromEntries(params.entries()));
  let mid = params.get('MerchantID');

  const createOrder = function(data, actions) {
    return fetch('https://www.computop-paygate.com/ExternalServices/paypalorders.aspx', {
        method: 'POST',
        body: params
    }).then(function (res) {
        return res.text();
    }).then(function(orderData) {
        let qData = new URLSearchParams(orderData)
        payid.current = qData.get('PayID');
        console.log('order-data', Object.fromEntries(qData.entries()));
        return qData.get('orderid');
    });
  };

  const onApprove = function (data, actions) {
    var rd = "MerchantId=" + mid + "&PayId=" + payid.current + "&OrderId=" + data.orderID;
    // Build an invisible form and directly submit it
    const form = document.createElement('form');
    form.method = 'POST';
    form.action = 'https://www.computop-paygate.com/cbPayPal.aspx?rd=' + window.btoa(rd);
    form.style.display = 'none';
    // Add form to body
    document.body.appendChild(form);
    // Submit form
    form.submit();
  };

  const onCancel = function (data, actions) {                    
    var rd = "MerchantId=" + mid + "&PayId=" + payid.current + "&OrderId=" + data.orderID;
    // Build an invisible form and directly submit it                    
    const form = document.createElement('form');
    form.method = 'POST';
    form.action = "https://www.computop-paygate.com/cbPayPal.aspx?rd=" + window.btoa(rd) + "&ua=cancel&token=" + data.orderID;
    form.style.display = 'none';
    // Add form to body
    document.body.appendChild(form);                    
    // Submit form                    
    form.submit();
  };

  const onError = function (data, actions) {         
    var rd = "MerchantId=" + mid + "&PayId=" + payid.current + "&OrderId=" + data.orderID;
    // Build an invisible form and directly submit it                    
    const form = document.createElement('form');
    form.method = 'POST';
    form.action = "https://www.computop-paygate.com/cbPayPal.aspx?rd=" + window.btoa(rd) + "&ua=cancel&token=" + data.orderID;
    form.style.display = 'none';
    // Add form to body
    document.body.appendChild(form);                    
    // Submit form                    
    form.submit();
  };


  return (
    <div className={[styles.orderButton, styles.paypal].join(' ')} >
      <PayPalScriptProvider options={{ clientId: config.computopFrontend?.paypalClientId, currency: config.computopFrontend?.currency }}>
        <PayPalButtons style={{ layout: "horizontal" }} {...{createOrder, onApprove, onCancel, onError}} />
      </PayPalScriptProvider>
    </div>
  );
}