import { notificationStore } from '@lws/lib-checkout';
import { Button } from '../ui/layout/button';
import styles from './notification.module.scss';
import { useTranslation } from 'react-i18next';

type TNotification = notificationStore.Notification;
type TNotificationAction = notificationStore.NotificationAction

const { NotificationActionType } = notificationStore;

const NotificationAction: React.FC<{notification: TNotification, action: TNotificationAction}> = (props) => {
  const {notification, action} = props;
  return <Button {...action.buttonProps} onClick={(e) => {
    e.stopPropagation();
    switch(action.type) {
    case NotificationActionType.TO_SHOP:
      notification.remove();
      break;
    }
  }} title={action.buttonLabel}></Button>;
};

export const Notification: React.FC<{notification: TNotification}> = ({ notification }) => {

  const { t } = useTranslation('common');

  const classNames = [
    styles.notification,
    ...notification.classNames || [],
    ...(notification.fadeIn ? [styles.fadeIn] : []),
    ...(notification.fadeOut ? [styles.fadeOut] : []),
  ];
  return (
    <li className={classNames.filter(Boolean).join(' ')} onClick={() => {
      notification.remove();
    }}>
      <div>
        {t(notification.message, notification.messageData)}
      </div>
      <div className={styles.actions}>
        {notification.actions.map((action, idx) => <NotificationAction key={idx} {...{notification, action}} />)}
      </div>
    </li>
  );
};
