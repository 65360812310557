import { computed } from 'nanostores';
import { $session, $sessionId } from '..';
import { useStore } from '@nanostores/react';
import { client } from '../../../client';
import { SetGuestEmail } from '../../../pre-defined-queries/set-guest-email';
import { handleSessionResponse } from '../util/handle-session-response';
import { IAddress, IAddressesTransaction, ICustomerInfoTransaction, IGuestInfoTransaction } from '../../../types';

import { SetAddresses } from '@/pre-defined-queries/set-addresses';

const setAddresses = async (sessionId: string, billing: IAddress, shipping: IAddress) => {
  const result = await client.exec(new SetAddresses({sessionId, billing, shipping}));
  handleSessionResponse(result);
}

export const useAddresses = () => {
  const addresses = useStore($address);
  const sessionId = $sessionId.get();
  return {
    set: (billing: IAddress, shipping: IAddress) => setAddresses(sessionId!, billing, shipping),
    ...addresses
  }
}

type AddressStoreType = { billing?: IAddress, shipping?: IAddress };

export const $address = computed($session, (session): AddressStoreType => {
  if(!session.transactions) return {};
  const transaction = session.transactions.find(t => t.__typename === 'AddressesTransaction') as IAddressesTransaction | undefined;
  if(transaction) return {
    billing: transaction.billing,
    shipping: transaction.shipping,
  };

  return {};
});