import { salonStore } from '@lws/lib-checkout';
import { SalonResult } from './salon-result';

export interface SalonResultsProps {
  setSalon: (salonId?: string) => void;
  choice?: string;
}

export const SalonResults = (props: SalonResultsProps) => {

  const { result } = salonStore.useSalonSearchResults();

  return (
    <div>
      {result.map(salonId =>
        <SalonResult key={salonId} salonId={salonId} setSalon={props.setSalon} choice={props.choice} />
      )}
    </div>
  );
};
