import { PropsWithChildren } from 'react';
import { Helmet } from 'react-helmet';
import { ProgressBar } from '../ui/progress/bar';
import { Page } from '@lws/lib-checkout';
import { WithTranslation, withTranslation } from 'react-i18next';

export const AddressLayout = withTranslation('common')((props: PropsWithChildren & WithTranslation) => {
  const { t } = props;
  return (
    <>
      <Helmet>
        <title>{t('Address')}</title>
      </Helmet>
      <ProgressBar step={Page.ADDRESS} />
      {props.children}
    </>
  );
});
