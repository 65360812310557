import { PropsWithChildren } from 'react';
import styles from './tab.module.scss';
import { Icon, IconComponent } from '../icons/icon';

export interface TabProps {
  name: string,
  onClick: ( ) => void,
  active: boolean
  icon: Icon


}

export const Tab = (props: PropsWithChildren<TabProps>) => {

  return (

    <div className={`${props.active ? styles['active-tab'] : styles['passive-tab']} ${styles['tab']}`} onClick={props.onClick}>
      <IconComponent icon={props.icon}/>
      <span className={styles['tabName']}>{props.name}</span>
    </div>

  );
};


