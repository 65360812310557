import { withValidation, DropDownSelection, useRules, useValidationContext } from '@lws/react-ui';

export const ValidatedDropDown = withValidation((props: React.ComponentProps<typeof DropDownSelection> & { validatorName: string }) => {

  const { domain } = useValidationContext(props.validatorName || '');
  const rules = useRules(domain);
  const required = !!rules?.find(rule => rule.name === 'RequiredRule' || rule.name === 'NonEmptyRule');

  return <DropDownSelection {...props} placeholder={required ? `${props.placeholder} *` : props.placeholder} />;
});
