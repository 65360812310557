import { Page, sessionStore } from '@lws/lib-checkout';
import { Validated } from '@lws/react-ui';
import { useEffect, useRef, useState } from 'react';
import { CustomerForm, GuestForm, UserInfoLayout } from '../components/user-info';
import styles from './user-info.module.scss';
import { useTranslation } from 'react-i18next';
import { SubmitButton } from '@/components/ui/form/submit-button';
import { Box } from '@/components/ui/layout/box';
import { Heading } from '@/components/ui/layout/heading';
import { events } from '@lws/lib-track';

const useEmailCheck = (checkEmail: (email: string) => Promise<{ hasAccount: boolean }>) => {
  const timerRef = useRef<ReturnType<typeof setTimeout>>(null);
  const [ hasAccount, setHasAccount ] = useState<boolean>(false);
  const [ isLoading, setIsLoading ] = useState<boolean>(false);
  useEffect(() => {
    return () => {
      if(timerRef.current) clearTimeout(timerRef.current);
    };
  });

  const callback = (email: string) => {
    if(timerRef.current) clearTimeout(timerRef.current);

    timerRef.current = setTimeout(() => {
      setIsLoading(true);
      checkEmail(email).then((response) => {
        setHasAccount(response.hasAccount);
        setIsLoading(false);
      });
    }, 500);
  };

  return {
    checkEmail: callback,
    hasAccount,
    isLoading,
  };
};

export const UserInfo = sessionStore.withPageGuard(Page.USER_INFO, <>NOT ALLOWED</>)(() => {
  const { t } = useTranslation('common');

  sessionStore.tracking.useTrack(new events.PwaPageView({
    pageType: 'checkout-user-info',
    path: '/user-info',
    referrer: window.document.referrer,
    category: 'checkout',
    title: 'UserInfo',
  }))

  const { setGuestEmail, login, logout, email, guest, customer } =  sessionStore.userInfo.useUserInfo();
  const { checkEmail } =  sessionStore.userInfo.useUserInfo();
  const { checkEmail: checkEmailDebounced, hasAccount, isLoading } = useEmailCheck(checkEmail);
  const onSubmitGuestForm: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    // @ts-expect-error
    if ((e.nativeEvent.submitter as HTMLButtonElement | undefined)?.name === 'as_guest') {
      setGuestEmail(formData.get('email') as string);
      return;
    }
    checkEmail(formData.get('email') as string || '').then(({ hasAccount }) => {
      if(hasAccount) login(formData.get('email') as string, formData.get('password') as string);
      else setGuestEmail(formData.get('email') as string);
    });
  };

  const onChangeGuestEmail = (newEmail: string) => {
    checkEmailDebounced(newEmail);
  };

  if(guest || guest === undefined) return (
    <UserInfoLayout>
      <form onSubmit={onSubmitGuestForm} className={styles['form']}>
        <Box>
          <Heading title={t('checkout.user_info.headline')} level={2} style={{border: 0}}/>
          <Validated name='user-info'>
            <GuestForm currentEmail={email} showPassword={hasAccount} isLoading={isLoading} onChangeEmail={onChangeGuestEmail} />
          </Validated>
        </Box>
        <SubmitButton clean name='as_guest' validFor='user-info.guest.email'>{t('checkout.user_info.button.continue_as_guest')}</SubmitButton>
      </form>
    </UserInfoLayout>
  );

  return (
    <UserInfoLayout>
      <Box>
        {customer ? (
          <CustomerForm customer={customer!} logout={() => logout()} />
        ) : ''}
      </Box>
    </UserInfoLayout>
  );
});
