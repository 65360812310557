export interface VisibleIconProps extends React.SVGAttributes<SVGSVGElement>{
}


export const VisibleIcon = (props: VisibleIconProps) => {
  return (
    <svg {...props} id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
      <path d="M99.44,48.82c-.5-3.83-7.04-13.31-9.66-16.5C69.12,7.07,33,6.1,11.49,30.86c-3,3.45-10.35,13.68-10.91,17.96-.09.72-.11,1.57,0,2.32,1.07,3.46,2.9,6.7,4.82,9.75,22.07,35.13,67.28,35.01,89.29-.12,1.89-3.02,4.59-8.57,4.75-9.64.16-1.06,0-2.32,0-2.32ZM93.53,50.99c-.14.93-2.98,5.76-4.63,8.39-19.15,30.57-58.5,30.68-77.71.1-1.67-2.66-3.76-5.48-4.7-8.49-.09-.65-.08-1.39,0-2.02.48-3.73,7.38-12.63,9.99-15.63,18.72-21.55,50.15-20.71,68.14,1.27,2.28,2.78,8.47,11.03,8.91,14.36,0,0,.14,1.09,0,2.02Z"/>
      <path d="M50,26.83c-12.74,0-23.07,10.33-23.07,23.07s10.33,23.07,23.07,23.07,23.07-10.33,23.07-23.07-10.33-23.07-23.07-23.07ZM50,67.61c-9.74,0-17.63-7.89-17.63-17.63s7.89-17.63,17.63-17.63,17.63,7.89,17.63,17.63-7.89,17.63-17.63,17.63Z"/>
    </svg>
  );
};
