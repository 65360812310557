import { persistentMap } from '@nanostores/persistent';
import { useStore } from '@nanostores/react';
import { CheckoutConfig } from '../types/config';

let navigateCallback: (target: string | URL) => void = (target) => window.location.replace(new URL(target));

export const getNavigateCallback = () => {
  return navigateCallback;
}

export const setNavigateCallback = (callback: (target: string | URL) => void) => {
  navigateCallback = callback;
}


export function init(config: CheckoutConfig) {
  $config.set(config);
}

const initialConfig: CheckoutConfig = {
  apiUrl: 'https://graph.labio/graphql',
  googleMapsApiKey: 'AIzaSyCse7qev-sFB6qEHE-IRUHARCCxddBeL5M',
}

export const $config = persistentMap<CheckoutConfig>('store-init-config-', initialConfig, {
  encode: JSON.stringify,
  decode: JSON.parse,
});