import { WithTranslation, withTranslation } from 'react-i18next';
import styles from './guest-form.module.scss';
import { Validated, withValidation } from '@lws/react-ui';
import { ValidatedInput } from '../ui/form/input';
import { SubmitButton } from '../ui/form/submit-button';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ForgotPassword } from './forgot-password';


const ValidationData = withValidation((props: { onChange?: (data: unknown) => void, value: unknown }) => {
  useEffect(() => {
    props.onChange?.(props.value);
  }, [props.value]);
  return <></>;
});

export interface GuestFormProps extends WithTranslation {
  currentEmail?: string;
  showPassword: boolean;
  isLoading?: boolean;
  onChangeEmail: (email: string) => void;
  emailFormElementName?: string
  passwordFormElementName?: string
}

export const GuestForm = withTranslation('common')((props: GuestFormProps) => {
  const { t } = props;
  const emailFormElementName = props.emailFormElementName || 'email';
  const passwordFormElementName = props.passwordFormElementName || 'password';

  return (
    <div className={styles.guestform}>
      <Validated name='guest'>
        <ValidationData validatorName='guest' name='guest' value={props.showPassword ? 'guest' : ''} />
        <ValidatedInput type='email' placeholder={t('checkout.user_info.input.email')}validatorName={emailFormElementName} name={emailFormElementName} onChange={(e) => {props.onChangeEmail(e as string); }} defaultValue={props.currentEmail} />
        { props.showPassword ? (
          <>
            <ValidatedInput validatorName={passwordFormElementName} type='password' placeholder={t('checkout.user_info.input.password')} name={passwordFormElementName} validateImmediately/>
            <ForgotPassword />
          </>
        ) : <></>}
      </Validated>
      <div className={styles['button']}>
        <SubmitButton primary disabled={props.isLoading} validFor='guest'>{ props.showPassword ? t('checkout.user_info.button.login') : t('checkout.user_info.button.continue') }</SubmitButton>
      </div>
    </div>
  );
});
