import { WithTranslation, withTranslation } from 'react-i18next';
import { ProgressBarElement } from './bar-element';
import styles from './bar.module.scss';
import { Page } from '@lws/lib-checkout';

export interface ProgressBarProps extends WithTranslation {
  step: Page;
}

export const ProgressBar = withTranslation('common')(({ step }: ProgressBarProps) => {
  const steps = [Page.USER_INFO, Page.ADDRESS, Page.PAYMENT, Page.REVIEW];
  return (
    <ul className={styles['bar']}>

      {steps.map((currentStep , index) => {
        return (<ProgressBarElement key={currentStep} isCurrent={currentStep === step} step={currentStep}/>);
      })}
    </ul>
  );
});
