import { BaseCheckoutAction } from '../action';
import { ICheckoutSession } from '../types';
import { ICheckoutConfig } from '../types/session/config';
import { PreDefinedQuery } from './base';

type Input = {
  sessionId: string;
}

type Result = {
  session: ICheckoutSession,
  actions: Array<BaseCheckoutAction>,
  config: ICheckoutConfig,
}

export class GetCheckoutSession extends PreDefinedQuery<Result> {
  constructor(public variables: Input) {
    super()
  }
  public readonly query: string = `
    query Session($sessionId: String!) {
      checkout {
        session(id: $sessionId) {
          orderNumber
          successUrl
          cancelUrl
          quote {
            items {
              name
              sku
              price
              priceMode
              qty
              taxClass
              id
              additionalAttributes {
                key
                value
              }
            }
          }
          ${this.getPartialTransactions()}
          id
          subSystem
          totals { name value }
        }
        config(id: $sessionId) {
          ${this.getPartialConfigFields()}
        }
      }
    }
  `;

  protected transformResponse(responseData: any): Result {
    const session: ICheckoutSession = responseData.data.checkout.session;
    const config: ICheckoutConfig = responseData.data.checkout.config;
    return { session, actions: [], config };
  }
}