import { persistentAtom } from '@nanostores/persistent';
import { useStore } from '@nanostores/react';
import { PropsWithChildren, ReactEventHandler, useEffect, useRef, useState } from 'react';

export const DebugTrigger = () => {
  const [ counter, setCounter ] = useState<number>(0);
  const timerRef = useRef<ReturnType<typeof setTimeout>>(null)
  const d = useDebug();

  useEffect(() => {
    if(timerRef.current) clearTimeout(timerRef.current);

    if(counter >= 5) {
      $debug.set(true);
      setCounter(0);
    } else {
      timerRef.current = setTimeout(() => {
        setCounter(0);
      }, 1000);
    }

    return () => {
      if(timerRef.current) clearTimeout(timerRef.current);
    }
  }, [counter])

  const onClick: ReactEventHandler<HTMLDivElement>  = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if($debug.get()) {
      $debug.set(false);
      return;
    }
    setCounter(c => ++c)
  }

  return (
    <div
      style={{position: 'fixed', bottom: 0, right: 0, width: 50, height: 50, backgroundColor: d ? 'red' : 'transparent'}}
      onMouseDown={onClick}
      onTouchStart={onClick}
    />
  );
}

export const Debug = (props: PropsWithChildren) => {
  const d = useDebug();
  if(!d) return <></>;

  return <>{ props.children }</>;
}

export const useDebug = () => {
  return useStore($debug);
}

export const $debug = persistentAtom<boolean>('lws-checkout/debug', false, {
  encode: JSON.stringify,
  decode: JSON.parse
});

if(typeof window !== 'undefined') {
  (window as any).enableLwsDebug = () => {
    $debug.set(true)
  }

  (window as any).disableLwsDebug = () => {
    $debug.set(false)
  }
}