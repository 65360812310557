import styles from './footer-navigation.module.scss';
import { Icon, IconComponent } from '../icons/icon';
import { withTranslation, WithTranslation } from 'react-i18next';
import { sessionStore } from '@lws/lib-checkout';
import { Link } from '../link';

interface FooterNaviProps extends WithTranslation {}

export const FooterNavi = withTranslation('common')((props: FooterNaviProps) => {
  const { t } = props;
  const { session } = sessionStore.useSession();
  return (
    <div className={`${styles.footerNavi} ${styles.pageWidth}`}>
      <div className={styles.iconLink}>
        <IconComponent icon={Icon.BACK} /><Link href='#' onClick={() => window.history.back()}>{t('checkout.footer.link.back')}</Link>
      </div>
      <div className={styles.iconLink}>
        <IconComponent icon={Icon.CART} /><Link href={session.cancelUrl}>{t('checkout.footer.link.cancel')}</Link>
      </div>
    </div>
  );
});
