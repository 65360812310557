import { Route, Routes } from 'react-router-dom';
import { BaseLayout } from '../layout/base';
import { Address } from './address';
import { Payment } from './payment';
import { ShowcaseAddress } from './showcase/address';
import { Showcase } from './showcase';
import { UserInfo } from './user-info';
import { Review } from './review';
import { ShowcaseBlock } from './showcase/block';

export const CheckoutRoutes = () => (
  <Routes>
    <Route path='/showcase' element={<Showcase />}>
      <Route path='address' element={<ShowcaseAddress />} />
      <Route path='block' element={<ShowcaseBlock />} />
    </Route>

    <Route path='/:sessionId' element={<BaseLayout />}>
      <Route path='address' element={<Address />} />
      <Route path='review' element={<Review />} />
      <Route path='user-info' element={<UserInfo />} />
      <Route path='payment' element={<Payment />} />
    </Route>
  </Routes>
)