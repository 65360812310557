import { computed } from 'nanostores';
import { $session, $sessionId } from '..';
import { useStore } from '@nanostores/react';
import { client } from '../../../client';
import { handleSessionResponse } from '../util/handle-session-response';
import { SetSalon } from '@/pre-defined-queries/set-salon';
import { ISalonTransaction } from '@/types/transaction/salon';

const setSalon = async (sessionId: string, salonId?: string) => {
  const result = await client.exec(new SetSalon({sessionId, salonId}));
  handleSessionResponse(result);
};

export const useCheckoutSalon = () => {
  const salon = useStore($checkoutSalon);
  const sessionId = $sessionId.get();
  return {
    saveSalon: (salonId?: string) => setSalon(sessionId!, salonId),
    ...salon,
  };
};

type SalonStoreType = { salonId?: string };

export const $checkoutSalon = computed($session, (session): SalonStoreType => {
  if(!session.transactions) return {};
  const transaction = session.transactions.find(t => t.__typename === 'SalonTransaction') as ISalonTransaction | undefined;
  if(transaction) return {
    salonId: transaction.salonId,
  };

  return {};
});
