import { useState } from 'react';
import { DisplayAddress } from '../../components/ui/address/display';
import { Box } from '../../components/ui/layout/box';
import { Icon } from '../../components/ui/icons/icon';
import { IAddress } from '@lws/lib-checkout';
import { BoxColumn} from '../../components/ui/layout/boxcolumn';
import { Button } from '../../components/ui/layout/button';
import { Heading } from '../../components/ui/layout/heading';
import { PrimaryButton } from '@lws/react-ui';

const exampleAddress: IAddress = {
  firstname: 'Nikolas',
  lastname: 'Meyer',
  addr1: 'Test 1',
  postcode: '12345',
  city: 'Test',
  country: 'DE',
};

export const ShowcaseAddress = () => {

  const [value, setName] = useState<string>('hola');
  const onChangeHandler = (event: any) => {
    if (event.target.value.startsWith('a')) {
      setName(event.target.value);
    } else {
      setName('a' + event.target.value);
    }
  };

  return (
    <>
      <header>
        <h1>Address</h1>
      </header>
      <section>
        <h2>Display</h2>
        <DisplayAddress address={exampleAddress} />
      </section>
      <section>
        <h2>Edit</h2>
        <Box>
          <Heading title='Der Salon Ihres Vertrauens' icon={Icon.SALONPIN}/>
        </Box>
        {/* <EditAddress address={exampleAddress} config={{allowedCountries: [], showPrefix: false}} /> */}
      </section>
      <section>
        <Box>
          <Heading title='Bestellübersicht'/>
          <BoxColumn width={1}>
            <input placeholder='hallo' value={value} onChange={onChangeHandler}/>
            <Button title='The button'/>
          </BoxColumn>
          <BoxColumn width={2}>
            <Heading level={3} title='Liefer- und Rechnungsadresse' button ></Heading>
            <p>Adresse</p>
            <Heading level={3} title='Liefer- und Rechnungsadresse' button ></Heading>
            <p>Adresse</p>
            <Heading level={3} title='Zahlungsart' button ></Heading>
            <p>PayPal</p>
            <Heading title='Gesamtsumme'></Heading>
            <Heading title='Zwischensumme'></Heading>
            <Heading title='Versandkosten'></Heading>
            <p>Gesamtbetrag</p>
            <PrimaryButton title='bestellen'>Jetzt kostenpflichtig bestellen</PrimaryButton>

          </BoxColumn>
        </Box>
      </section>
    </>
  );
};
