import { PropsWithChildren } from 'react';
import styles from './boxcolumn.module.scss';

export interface BoxColumnProps extends React.HTMLAttributes<HTMLDivElement> {
  width: number;

}

export const BoxColumn = (props: PropsWithChildren<BoxColumnProps>) => {
  const defaultClasses = styles['column'];
  const className = props.className ? `${props.className} ${defaultClasses}` : defaultClasses;
  const {width, ...passProps} = props; // eslint-disable-line @typescript-eslint/no-unused-vars
  return (
    <div  {...passProps} className={className} style={{flex: props.width}}>
      {props.children}
    </div>
  );
};

