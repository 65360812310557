import { Salon } from '@/types/salon';
import { PreDefinedQuery } from './base';

type Input = {
  salonId: string,
};

type Result = Salon | undefined;

export class GetSalon extends PreDefinedQuery<Result> {

  constructor(public variables: Input) {
    super();
  }

  public readonly query: string = `
    query Salon($salonId: ID) {
      salon(salon_id: $salonId) {
        salon_id
        name
        distance
        filter {
          hair
          skin
          color
          makeup
        }
        info {
          headline
          description
          opening_hours
        }
        owner {
          salutation
          firstname
          lastname
        }
        location {
          address
          postcode
          city
          country
          coordinates {
            lat
            long
          }
        }
        flags {
          sfb
          hr_platform
          climate_neutral_salon
          salon_comission
          top_employer
          premium_salon
        }
        contact {
          phone
          website
          email
          social {
            type
            name
          }
        }
        images {
          id
          b64(preset: "thumb-100-fit-b64-webp")
          srcset(preset: "salon")
        }
      }
    }`;

  protected transformResponse(responseData: { data?: { salon: Salon }}): Result {
    if (!responseData.data?.salon) {
      console.error('failed to fetch salon ', this.variables.salonId);
    }
    return responseData?.data?.salon;
  }
}
