export interface CheckboxProps extends React.SVGAttributes<SVGSVGElement>{
}


export const Checkbox = (props: CheckboxProps) => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="14" height="10" viewBox="0 0 14 10" fill="none">
      <path d="M12.0864 0.634993L4.38758 7.7919L2.02304 4.24647L2.023 4.24641C1.81093 3.92863 1.38055 3.8389 1.06011 4.05274L1.19889 4.26069L1.06011 4.05274C0.742356 4.2648 0.652609 4.69513 0.866398 5.01556C0.866412 5.01558 0.866427 5.01561 0.866441 5.01563L3.6836 9.23989C3.7957 9.41143 3.97836 9.52097 4.17512 9.54534C4.21117 9.5507 4.24359 9.55063 4.25708 9.5506C4.25796 9.5506 4.25876 9.5506 4.25947 9.5506C4.4345 9.5506 4.60251 9.48588 4.73223 9.36477C4.73224 9.36476 4.73225 9.36475 4.73226 9.36474L13.0271 1.65351C13.0273 1.65332 13.0275 1.65314 13.0276 1.65296C13.3118 1.39146 13.3226 0.952024 13.0657 0.67229L13.0657 0.67229C12.8051 0.388452 12.3647 0.37522 12.0864 0.634993Z" fill="#1E8600" stroke="#1E8600" stroke-width="0.5"></path>
    </svg>
  );
};
