import { useMapsLibrary } from '@vis.gl/react-google-maps';
import { useState, useRef, useEffect, useMemo } from 'react';
import { Input } from '../form/input';
import { Button, DropDownSelection } from '@lws/react-ui';
import { SalonSearch, sessionStore } from '@lws/lib-checkout';
import styles from './salon-search.module.scss';
import { Heading } from '../layout/heading';
import { useTranslation, withTranslation, WithTranslation } from 'react-i18next';

const RADIUS_OPTIONS = [
  {
    label: '5 km',
    value: 5,
  },
  {
    label: '10 km',
    value: 10,
  },
  {
    label: '25 km',
    value: 25,
  },
  {
    label: '50 km',
    value: 50,
  },
];

export interface PlaceSearchProps extends WithTranslation {
  onPlaceSelect: (place: google.maps.places.PlaceResult | null) => void;
  country?: string,
}

export const PlaceSearch = withTranslation('common')(({t, country, onPlaceSelect}: PlaceSearchProps) => {
  const [placeAutocomplete, setPlaceAutocomplete] =
    useState<google.maps.places.Autocomplete | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const places = useMapsLibrary('places');

  useEffect(() => {
    if (!places || !inputRef.current) return;

    const options: google.maps.places.AutocompleteOptions = {
      fields: ['geometry', 'name', 'formatted_address'],
      ...(country ?
        {
          componentRestrictions: {
            country,
          },
        } : {}),
    };

    setPlaceAutocomplete(new places.Autocomplete(inputRef.current, options));
  }, [places]);

  useEffect(() => {
    if (!placeAutocomplete) return;

    placeAutocomplete.addListener('place_changed', () => {
      onPlaceSelect(placeAutocomplete.getPlace());
    });
  }, [onPlaceSelect, placeAutocomplete]);

  return (
    <div className="autocomplete-container">
      <Input name='location' ref={inputRef} placeholder={t('checkout.salon_finder.modal.input.place')}/>
    </div>
  );
});

export const SalonSearchField = ({ isOpen, onSearch }: { isOpen: boolean, onSearch: (search: SalonSearch) => void }) => {

  const { t } = useTranslation('common');

  const [location, setLocation] = useState<{ lat?: number, long?: number }>({});
  const [radius, setRadius] = useState<number>(10);

  useEffect(() => {
    if (isOpen){
      window.navigator.geolocation.getCurrentPosition((position) => {
        setLocation(location => {
          if (location.lat === undefined) {
            search({
              lat: position.coords.latitude,
              long: position.coords.longitude,
            });
            return { lat: position.coords.latitude, long: position.coords.longitude };
          }
          return location;
        });
      });
    }
  }, [isOpen]);

  const { billing } = sessionStore.address.useAddresses();

  const search = useMemo(() => (search: Partial<SalonSearch>) =>
    onSearch({
      random: true,
      country: billing?.country || 'DE',
      size: 100,
      lat: location.lat!,
      long: location.long!,
      radius,
      ...search,
    }), [billing?.country, location, radius]);

  const updateLocation = (location: { lat?: number, long?: number }) => {
    if (location.lat && location.long) {
      setLocation(location);
    }
  };
  const updateRadius = (radius?: number) => {
    if (radius && location.lat && location.long) {
      setRadius(radius);
    }
  };

  return (
    <>
      <Heading level={2} title={t('checkout.salon_finder.modal.title')} className={styles['heading']}></Heading>
      <div className={styles['search']}>
        <div className={styles.location}>
          <PlaceSearch
            country={billing?.country}
            onPlaceSelect={(place) => {
              updateLocation({ lat: place?.geometry?.location?.lat(), long: place?.geometry?.location?.lng() });
            }}/>
        </div>
        <div className={styles.radius}>
          <DropDownSelection name='radius' options={RADIUS_OPTIONS} selected={radius} onChange={updateRadius}/>
        </div>
        <Button primary onClick={() => search({})}>{t('checkout.salon_finder.modal.button.search')}</Button>
      </div>
    </>
  );
};
