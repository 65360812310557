import { atom } from 'nanostores';
import { Duration } from '@nimey/units';
import { useStore } from '@nanostores/react';

export enum NotificationActionType {
    TO_SHOP = 'to_shop',
}

export type NotificationAction = {
  type: NotificationActionType;
  buttonLabel: string;
  buttonProps?: Record<string, number | boolean | string>;
}

export type Notification = {
  id: string;
  fadeIn: boolean;
  fadeOut: boolean;
  removeDelay?: number | Duration;
  disableDelay?: boolean;
  message: string;
  messageData?: Record<string, string>;
  classNames?: Array<string>;
  actions: Array<NotificationAction>;
  remove: () => void;
}

export const useNotification = () => {
  const notifications = useStore($notifications);
  return { notifications, notify };
}

export const $notifications = atom<Array<Notification>>([]);

export const notify = (notification: Omit<Notification, 'id' | 'fadeIn' | 'fadeOut' | 'remove'>) => {
  const id = crypto.randomUUID();
  const delay = notification.removeDelay || Duration.seconds(10);


  const fadeIn = () => $notifications.set([...$notifications.get().map(n => n.id === id ? {...n, fadeIn: true} : n)]);
  const fadeOut = () => $notifications.set([...$notifications.get().map(n => n.id === id ? {...n, fadeOut: true} : n)]);
  const remove = () => $notifications.set([...$notifications.get().filter(n => n.id !== id)]);

  $notifications.set([...$notifications.get(), {...notification, id, fadeIn: false, fadeOut: false, remove: () => {
    fadeOut();
    setTimeout(remove, +Duration.millis(500));
  }}]);

  setTimeout(fadeIn, +Duration.millis(500));
  if(!notification.disableDelay) {
    setTimeout(fadeOut, +Duration.millis(500) + +delay);
    setTimeout(remove, +Duration.millis(1000) + +delay);
  }
}
