import { ICustomer } from '@lws/lib-checkout';
import { Button } from '@lws/react-ui';
import { WithTranslation, withTranslation } from 'react-i18next';
import styles from './customer-form.module.scss';
import { useNavigate, useParams } from 'react-router-dom';

export interface CustomerFormProps extends WithTranslation {
  customer: ICustomer;
  logout: () => void;
}

export const CustomerForm = withTranslation('common')((props: CustomerFormProps) => {
  const navigate = useNavigate();
  const { sessionId } = useParams();
  const { t } = props;
  return (
    <>
      <p>Bestellen als {props.customer.firstname} {props.customer.lastname}</p>
      <div><Button secondary onClick={() => props.logout()}>{t('checkout.user_info.button.logout')}</Button></div>
      <div className={styles['button']}>
        <Button primary onClick={() => navigate(`/${sessionId}/address`)}>{t('checkout.user_info.button.continue')}</Button>
      </div>
    </>
  );
});
