
import { PaymentLayout } from '@/components/payment/layout';
import { notificationStore, Page, PaymentAuthorizeStatus, sessionStore } from '@lws/lib-checkout';
import { PaymentMethodList } from '@/components/payment/method-list';
import { Button } from '@lws/react-ui';
import styles from './payment.module.scss';
import { WithTranslation, withTranslation } from 'react-i18next';
import { events } from '@lws/lib-track';

export const Payment = withTranslation('common')(sessionStore.withPageGuard(Page.PAYMENT, <>NOT ALLOWED</>)((props: WithTranslation) => {
  const { t } = props;
  sessionStore.tracking.useTrack(new events.PwaPageView({
    pageType: 'checkout-payment',
    path: '/payment',
    referrer: window.document.referrer,
    category: 'checkout',
    title: t('Payment'),
  }))
  const { config } = sessionStore.useSession();
  const { paymentMethod, setPaymentMethodCode, authorizeTransaction } = sessionStore.payment.usePaymentMethod();
  const paymentMethods = config.paymentMethods || [];

  const onSubmitPaymentForm: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const paymentMethodCode = formData.get('payment-method');
    if(!paymentMethodCode) {
      notificationStore.notify({
        actions: [],
        message: 'error.payment_method_required',
      });
      return;
    }
    setPaymentMethodCode(paymentMethodCode as string);
  };


  return (
    <>
      <form onSubmit={onSubmitPaymentForm}>
        <PaymentLayout>
          {authorizeTransaction && authorizeTransaction.status === PaymentAuthorizeStatus.FAILED ? (
            <p className={styles.error}>{t('checkout.payment.failed')}</p>
          ) : ''}
          <PaymentMethodList paymentMethods={paymentMethods} paymentMethod={paymentMethod}>
          </PaymentMethodList>
        </PaymentLayout>
        <Button className={styles.button} primary type='submit'>{t('checkout.payment.button.continue')}</Button>
      </form>
    </>
  );
}));
