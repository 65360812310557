import { sessionStore } from '@lws/lib-checkout';
import { Item } from './item';
import { Heading } from '../ui/layout/heading';
import { WithTranslation, withTranslation } from 'react-i18next';

export const Items = withTranslation('common')((props: WithTranslation) => {
  const { t } = props;
  const { session } = sessionStore.useSession();
  const items = session?.quote?.items || [];
  return (
    <>
      <ul>
        <Heading level={3} title={t('checkout.review.ordered_items')}/>
        {(items || []).map((item) => <Item key={item.sku} {...{item}} />)}
      </ul>
    </>
  );
});
