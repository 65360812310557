import { Modal, Validated } from '@lws/react-ui';
import { FormEventHandler, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ValidatedInput } from '../ui/form/input';
import { SubmitButton } from '../ui/form/submit-button';
import { sessionStore }from '@lws/lib-checkout';
import { Row } from '../ui/layout/row';
import styles from './forgot-password.module.scss';

export const ForgotPassword = withTranslation('common')((props) => {
  const { t } = props;
  const [modalOpen, setModalOpen] = useState(false);
  const { resetPassword } = sessionStore.userInfo.useUserInfo();
  const onSubmit: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const formData = new FormData(event.currentTarget);
    if (typeof formData.get('email') === 'string' && formData.get('email')) {
      resetPassword(formData.get('email') as string);
      setModalOpen(false);
    }
  };

  return (
    <>
      <Link className={styles.forgotPassword} to={'#'} onClick={() => setModalOpen(true)}>{t('checkout.user_info.button.forgot_password')}</Link>
      <Modal open={modalOpen} setOpen={setModalOpen} title={t('checkout.user_info.forgot_password.title')}>
        <p>{t('checkout.user_info.forgot_password.description')}</p>
        <form onSubmit={onSubmit} >
          <Validated name='forgot-password'>
            <Row>
              <ValidatedInput validatorName='email' name='email' placeholder={t('checkout.user_info.forgot_password.email')}/>
            </Row>
            <Row>
              <SubmitButton validFor='email'>{t('checkout.user_info.forgot_password.reset')}</SubmitButton>
            </Row>
          </Validated>
        </form>
      </Modal>
    </>
  );
});
