import { client } from '@/client';
import { GetSalonPage } from '@/pre-defined-queries/get-salons';
import { $salons, $salonSearchResult } from '../store';
import { SalonSearch } from '@/types/salon';

export async function searchSalons(search: SalonSearch) {
  const result = await client.exec(new GetSalonPage(search));
  for (const salon of result?.entities || []) {
    $salons.setKey(salon.salon_id, salon);
  }
  $salonSearchResult.setKey('result', (result?.entities || []).map(salon => salon.salon_id));
}
