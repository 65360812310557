import { ColorIcon, MakeupIcon, ScissorIcon, SkinIcon } from '@lws/react-ui';
import { UserIcon } from './user-icon';
import { InvisibleIcon } from './invisible-icon';
import { BackIcon } from './back-icon';
import { EditIcon } from './edit-icon';
import { CardIcon } from './card-icon';
import { CartIcon } from './cart-icon';
import { ErrorIcon } from './error-icon';
import { VisibleIcon } from './visible-icon';
import { HouseIcon } from './house-icon';
import { ParcelBoxIcon } from './parcel-box-icon';
import { PostOfficeIcon } from './post-office-icon';
import { ReviewIcon } from './review-icon';
import { SalonPinIcon } from './salon-pin-icon';
import { ShippingIcon } from './shipping-icon';
import styles from './icon.module.scss';

export enum Icon {
  EDIT = 'EditIcon',
  USER = 'User',
  INVISIBLE = 'Invisible',
  BACK = 'Back',
  CARD = 'Card',
  CART = 'Cart',
  ERROR = 'Error',
  VISIBLE = 'Visible',
  HOUSE = 'House',
  PARCELBOX = 'ParcelBox',
  POSTOFFICE = 'PostOffice',
  REVIEW = 'Review',
  SALONPIN = 'SalonPin',
  SHIPPING = 'Shipping',
  SCISSORS = 'Scissors',
  SKIN = 'Skin',
  COLOR = 'Color',
  MAKEUP = 'Makeup'
}

export interface IconComponentProps extends React.SVGAttributes<SVGSVGElement>{
  icon?: Icon;
}


export const IconComponent = (props: IconComponentProps) => {
  switch (props.icon) {
  case Icon.EDIT:
    return <EditIcon className={styles.default} {...props}/>;
  case Icon.USER:
    return <UserIcon className={styles.default} {...props}/>;
  case Icon.INVISIBLE:
    return <InvisibleIcon className={styles.default} {...props}/>;
  case Icon.BACK:
    return <BackIcon className={styles.default} {...props}/>;
  case Icon.CARD:
    return <CardIcon className={styles.default} {...props}/>;
  case Icon.CART:
    return <CartIcon className={styles.default} {...props}/>;
  case Icon.ERROR:
    return <ErrorIcon className={styles.default} {...props}/>;
  case Icon.VISIBLE:
    return <VisibleIcon className={styles.default} {...props}/>;
  case Icon.HOUSE:
    return <HouseIcon className={styles.default} {...props}/>;
  case Icon.PARCELBOX:
    return <ParcelBoxIcon className={styles.default} {...props}/>;
  case Icon.POSTOFFICE:
    return <PostOfficeIcon className={styles.default} {...props}/>;
  case Icon.REVIEW:
    return <ReviewIcon className={styles.default} {...props}/>;
  case Icon.SALONPIN:
    return <SalonPinIcon className={styles.defaultPin} {...props}/>;
  case Icon.SHIPPING:
    return <ShippingIcon className={styles.default} {...props}/>;
  case Icon.SCISSORS:
    return <ScissorIcon className={styles.default} {...props}/>;
  case Icon.SKIN:
    return <SkinIcon className={styles.default} {...props}/>;
  case Icon.COLOR:
    return <ColorIcon className={styles.default} {...props}/>;
  case Icon.MAKEUP:
    return <MakeupIcon className={styles.default} {...props}/>;
  }
  return <></>;
};
