import { PropsWithChildren, useMemo } from 'react';
import styles from './row.module.scss';

export interface RowProps {
  split?: Array<number>;
  mobileNoSplit? : boolean;
}

const getTemplateColumns = (split: RowProps['split']): string => {
  if(!split) return '';

  const sum = split.reduce((acc, w) => acc + w , 0 ) ;
  return split.map(w => `${Math.round((w / sum * 100)*10 )/10 }fr`).join(' ');
};

export const Row = (props: PropsWithChildren<RowProps>) => {
  const columns = useMemo(() => getTemplateColumns(props.split), [props.split]);

  return (
    <div className={`${styles.row} ${props.mobileNoSplit ? styles.mobile : styles.desktop}`} style={{gridTemplateColumns: columns}}>
      {props.children}
    </div>
  );
};
