export interface ParcelBoxIconProps extends React.SVGAttributes<SVGSVGElement>{
}


export const ParcelBoxIcon = (props: ParcelBoxIconProps) => {
  return (
    <svg {...props} id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
      <path d="M43.54,72.16h-5.79c-.83,0-1.5-.67-1.5-1.5s.67-1.5,1.5-1.5h5.79c.83,0,1.5.67,1.5,1.5s-.67,1.5-1.5,1.5Z"/>
      <path d="M96.73.5H3.27C1.74.5.5,1.74.5,3.27v7.34c0,1.53,1.24,2.77,2.77,2.77h.56v84.89c0,.68.55,1.23,1.23,1.23h89.88c.68,0,1.23-.55,1.23-1.23V13.37h.56c1.53,0,2.77-1.24,2.77-2.77V3.27c0-1.53-1.24-2.77-2.77-2.77h0ZM29.32,14.37v17.07H7.79V14.37h21.53ZM70.68,57.44h21.53v17.07h-21.53v-17.07ZM70.68,52.97v-17.07h21.53v17.07h-21.53ZM66.21,74.5h-32.42v-38.6h32.42v38.6ZM66.21,78.97v17.07h-32.42v-17.07h32.42ZM7.79,35.91h21.53v17.07H7.79v-17.07ZM7.79,57.44h21.53v17.07H7.79v-17.07ZM33.79,31.44V14.37h31.92v17.07h-31.92ZM7.79,78.97h21.53v17.07H7.79v-17.07ZM70.68,96.04v-17.07h21.53v17.07h-21.53ZM92.21,31.44h-21.53V14.37h21.53v17.07ZM95.54,9.61c0,.16-.14.3-.3.3H4.77c-.16,0-.3-.14-.3-.3v-4.84c0-.16.14-.3.3-.3h90.47c.16,0,.3.14.3.3v4.84ZM38.94,65.82h22.12c.68,0,1.23-.55,1.23-1.23v-23.53c0-.68-.55-1.23-1.23-1.23h-22.12c-.68,0-1.23.55-1.23,1.23v23.53c0,.68.55,1.23,1.23,1.23ZM41.17,43.29h17.66v19.07h-17.66v-19.07Z"/>
    </svg>
  );
};
