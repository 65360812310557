export interface ErrorIconProps extends React.SVGAttributes<SVGSVGElement>{
}


export const ErrorIcon = (props: ErrorIconProps) => {
  return (
    <svg {...props} id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
      <path d="M95.84,98.46c-.7,0-1.41-.27-1.94-.81L1.9,5.65c-1.07-1.07-1.07-2.81,0-3.89,1.07-1.07,2.81-1.07,3.89,0l92,92c1.07,1.07,1.07,2.81,0,3.89-.54.54-1.24.81-1.94.81Z"/>
      <path d="M3.84,98.46c-.7,0-1.41-.27-1.94-.81-1.07-1.07-1.07-2.81,0-3.89L93.9,1.77c1.07-1.07,2.81-1.07,3.89,0,1.07,1.07,1.07,2.81,0,3.89L5.78,97.65c-.54.54-1.24.81-1.94.81Z"/>
    </svg>
  );
};
