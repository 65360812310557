export interface ShippingIconProps extends React.SVGAttributes<SVGSVGElement>{
}


export const ShippingIcon = (props: ShippingIconProps) => {
  return (
    <svg {...props} id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 125.52 100">
      <path d="M17.21,33.29h21.12c1.38,0,2.5-1.12,2.5-2.5s-1.12-2.5-2.5-2.5h-21.12c-1.38,0-2.5,1.12-2.5,2.5s1.12,2.5,2.5,2.5Z"/>
      <path d="M31.1,45.86c0-1.38-1.12-2.5-2.5-2.5H3.48c-1.38,0-2.5,1.12-2.5,2.5s1.12,2.5,2.5,2.5h25.12c1.38,0,2.5-1.12,2.5-2.5Z"/>
      <path d="M28.45,58.46c0-1.38-1.12-2.5-2.5-2.5h-14.63c-1.38,0-2.5,1.12-2.5,2.5s1.12,2.5,2.5,2.5h14.63c1.38,0,2.5-1.12,2.5-2.5Z"/>
      <path d="M121.77,47.09c-1.7-2.2-6.25-4.83-8.07-5.83-1.97-3.05-11.67-18.1-13.31-20.62l-.15-.23c-1.94-2.98-4.86-7.49-10.85-7.49H31.33c-1.38,0-2.5,1.12-2.5,2.5s1.12,2.5,2.5,2.5h44.09v25.13c0,1.38,1.12,2.5,2.5,2.5h33.06s.08-.01.13-.01c2.36,1.28,5.82,3.45,6.72,4.61,1.72,2.22,1.72,4.23,1.72,6.78v13h-6.12c-1.43-6.22-7-10.87-13.64-10.87s-12.21,4.65-13.64,10.87h-24.5c-1.43-6.22-7-10.87-13.64-10.87s-12.21,4.65-13.64,10.87h-10.75c-1.38,0-2.5,1.12-2.5,2.5s1.12,2.5,2.5,2.5h10.52c.92,6.85,6.78,12.15,13.87,12.15s12.96-5.3,13.87-12.15h24.04c.92,6.85,6.78,12.15,13.87,12.15s12.96-5.3,13.87-12.15h8.39c1.38,0,2.5-1.12,2.5-2.5v-15.5c0-2.79,0-6.27-2.76-9.84ZM80.41,17.92h8.99c3.04,0,4.68,2.16,6.66,5.21l.15.23c1.28,1.97,7.51,11.63,11.09,17.18h-26.88v-22.63ZM47.99,82.08c-4.97,0-9.01-4.04-9.01-9.01s4.04-9.01,9.01-9.01,9.01,4.04,9.01,9.01-4.04,9.01-9.01,9.01ZM99.78,82.08c-4.97,0-9.01-4.04-9.01-9.01s4.04-9.01,9.01-9.01,9.01,4.04,9.01,9.01-4.04,9.01-9.01,9.01Z"/>
    </svg>
  );
};
