import { $config, $session } from '..';
import { client } from '../../../client';
import { GetCheckoutSession } from '../../../pre-defined-queries/get-checkout-session';

let isLoading = false;

export const loadSession = async (sessionId: string | undefined) => {
  if(isLoading) return;
  isLoading = true;
  if(!sessionId) return;

  const result = await client.exec(new GetCheckoutSession({sessionId}));
  $session.set(result.session);
  $config.set(result.config);
  isLoading = false;
}