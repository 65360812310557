import { Page } from '@/types';
import { PageGuard } from '../components';
import { ReactElement } from 'react';

export const withPageGuard = (page: Page, fallback: ReactElement) => {
  return function <P extends {}>(Component: React.ComponentType<P>) {
    const NewComponent = (props: P) => {
      return (
        <PageGuard page={page} fallback={fallback}>
          <Component {...props} />
        </PageGuard>
      )
    }

    return NewComponent;
  }
}