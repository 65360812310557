import styles from './identity.module.scss';
import logo from '../../../assets/logo.svg';

export const Identity = () => {
  return (
    <div className={styles.identity}>
      <span className={styles.claim}>the culture of beauty</span>
      <div className={styles.logo}>
        <img src={logo} />
      </div>
      <div className={styles.spacer} />
    </div>
  );
};
