import { AddressType, IAddress } from '@lws/lib-checkout';
import { prefix as p } from './prefix';

export const addressFormPrefixedFormData = (formData: FormData, prefix?: string): Partial<IAddress> => ({
  prefix: formData.get(p('prefix', prefix)) as string | undefined,
  firstname: formData.get(p('firstname', prefix)) as string | undefined,
  lastname: formData.get(p('lastname', prefix)) as string | undefined,
  addr1: formData.get(p('addr1', prefix)) as string | undefined,
  addr2: formData.get(p('addr2', prefix)) as string | undefined,
  addr3: formData.get(p('addr3', prefix)) as string | undefined,
  postcode: formData.get(p('postcode', prefix)) as string | undefined,
  city: formData.get(p('city', prefix)) as string | undefined,
  country: formData.get(p('country', prefix)) as string | undefined,
  addressType: formData.get(p('addressType', prefix)) as AddressType || undefined,
})