import { compareAddresses } from '@/util/compare-address';
import { sessionStore } from '@lws/lib-checkout';
import { ReviewAddress } from './address';

export const ReviewAddresses = () => {
  const { billing, shipping } = sessionStore.address.useAddresses();
  const billingEqualShipping = compareAddresses(billing, shipping);

  return (
    <div>
      {billingEqualShipping ? (
        <ReviewAddress title='combined' address={billing!} />
      ) : (
        <>
          <ReviewAddress title='billing' address={billing!} />
          <ReviewAddress title='shipping' address={shipping!} />
        </>
      )}
    </div>
  );
};
