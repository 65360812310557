import { BaseUpdateSession, UpdateSessionResult } from './base-update-session';

type Input = {
  sessionId: string;
}

export class PlaceSalesOrder extends BaseUpdateSession {
  constructor(public variables: Input) {
    super()
  }
  public readonly query: string = `
    mutation PlaceOrder($sessionId: String!) {
      checkout {
        placeOrder(sessionId: $sessionId) {
          ${this.getPartialSessionResponse()}
        }
      }
    }
  `;
  
    protected transformResponse(responseData: any) {
      return super.transformResponse(responseData, 'placeOrder')
    }
}