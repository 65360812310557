export interface SalonPinIconProps extends React.SVGAttributes<SVGSVGElement>{
}


export const SalonPinIcon = (props: SalonPinIconProps) => {
  return (
    <svg {...props} id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
      <path d="M49.95,30.62c5.66-7.75,16.89-5.88,19.98,3.28,2.23,6.62-2.79,13.74-7.09,18.4-3.88,4.21-8.5,7.66-13.05,11.08-.49.05-6.27-4.82-7.09-5.56-5.35-4.81-13.69-13.09-13.16-20.87.76-11.21,13.93-15.59,20.41-6.32Z"/>
      <path d="M35.9,3.11C8.64,13.08.46,47.08,19.39,68.96c7.18,8.3,16.57,16.55,24.33,24.54,1.91,1.96,3.71,4.07,5.65,6h.23c5.99-5.96,11.98-11.93,17.98-17.88,7.8-7.74,16.34-14.2,20.43-24.83C100.73,23.66,69.29-9.1,35.9,3.11ZM83.01,55.4c-3.55,9.23-10.96,14.84-17.74,21.57-5.21,5.17-10.41,10.36-15.62,15.53h-.2c-1.69-1.68-3.25-3.51-4.91-5.21-6.74-6.94-14.9-14.1-21.14-21.32C6.97,46.96,14.07,17.43,37.75,8.77c29-10.61,56.32,17.86,45.26,46.63Z"/>
    </svg>
  );
};
