export interface BackIconProps extends React.SVGAttributes<SVGSVGElement>{
}


export const BackIcon = (props: BackIconProps) => {
  return (
    <svg {...props} id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
      <path d="M37.65,50l30.36-26.41c1.12-.97,1.24-2.67.26-3.79-.9-1.03-2.58-1.31-3.79-.26l-32.47,28.24c-.64.55-1,1.38-.93,2.23-.06.85.3,1.68.93,2.23l32.47,28.24c1.22,1.05,2.89.77,3.79-.26.97-1.12.86-2.82-.26-3.79l-30.36-26.41Z"/>
      <g id="_27-approved_-check_-square_-success_-tick" data-name="27-approved,-check,-square,-success,-tick">
        <path id="Shape" d="M85.75.5c7.39,0,13.75,5.96,13.75,13.75v71.5c0,7.39-5.95,13.75-13.75,13.75H14.25c-7.39,0-13.75-5.98-13.75-13.75V14.25C.5,6.86,6.41.5,14.25.5h71.5ZM14.25,6c-4.39,0-8.25,3.53-8.25,8.25v71.5c0,4.39,3.49,8.25,8.25,8.25h71.5c4.39,0,8.25-3.61,8.25-8.25V14.25c0-4.39-3.62-8.25-8.25-8.25H14.25Z"/>
      </g>
    </svg>
  );
};
