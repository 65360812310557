import { salonStore } from '@lws/lib-checkout';
import { Heading } from '../layout/heading';
import { Button } from '@lws/react-ui';
import styles from './salon-results.module.scss';
import { Icon, IconComponent } from '../icons/icon';
import { WithTranslation, withTranslation } from 'react-i18next';

export interface SalonResultProps {
  salonId: string;
  setSalon: (salonId?: string) => void;
  choice?: string;
}


export const SalonResult = withTranslation('common')((props: SalonResultProps & WithTranslation) => {

  const { t } = props;
  const salon = salonStore.useSalon(props.salonId);

  return (
    <div className={styles['salon']}>
      <Heading
        className={styles['heading']}
        level={2}
        title={salon.name}
      >
      </Heading>
      <div className={styles.salonInfo}>
        <div>
          <div className={styles['icons']}>
            {salon.filter.hair ? <IconComponent icon={Icon.SCISSORS}/> : <></>}
            {salon.filter.skin ? <IconComponent icon={Icon.SKIN}/> : <></>}
            {salon.filter.color ? <IconComponent icon={Icon.COLOR}/> : <></>}
            {salon.filter.makeup ? <IconComponent icon={Icon.MAKEUP}/> : <></>}
          </div>
          <div className={styles['address']}>
            {salon.owner.map(owner => {
              return owner.salutation
                ? <span>{owner.salutation} {owner.firstname} {owner.lastname}</span>
                : <span>{owner.firstname} {owner.lastname}</span>;
            })
            }
            {salon.location.address ? <span>{salon.location.address}</span> : <></>}
            <div>
              {salon.location.postcode ? <span>{salon.location.postcode} </span> : <></>}
              {salon.location.city ? <span>{salon.location.city}</span> : <></>}
            </div>
          </div>
        </div>
        <img className={styles.image} height='100px' srcSet={salon.images.find(img => img.srcset)?.srcset} />
      </div>
      { props.choice === salon.salon_id
        ? <Button className={styles.select} secondary onClick={() => props.setSalon(undefined)}>{t('checkout.salon_finder.modal.button.remove')}</Button>
        : <Button className={styles.select} secondary onClick={() => props.setSalon(salon.salon_id)}>{t('checkout.salon_finder.modal.button.choose')}</Button>
      }
    </div>
  );
});
