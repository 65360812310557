import { IPaymentMethod } from '@lws/lib-checkout';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Radio } from '../ui/form/radio';
import styles from './method.module.scss';

interface Props extends WithTranslation {
  method: IPaymentMethod;
  currentMethodCode?: string;
}

export const PaymentMethod = withTranslation('common')(({ t, method, currentMethodCode }: Props) => {
  return (

    <Radio
      className={styles.method}
      required={true}
      value={method.code}
      name='payment-method'
      defaultChecked={currentMethodCode === method.code}>
      <span className={styles.methodName} >{t(`checkout.payment.method.${method.code}`)}</span>
      <img className={styles.methodImage} src={t(`checkout.payment.image.${method.code}`)} />
      <div></div>
    </Radio>
  );
});
