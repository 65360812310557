import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import App from './components/core/app';
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import { configStore, sessionStore } from '@lws/lib-checkout';
import { configStore as contentConfigStore } from '@lws/lib-content';
import { $ruleStore } from '@lws/react-ui';
import { DefaultValidatorFactory } from '@lws/lib-validate';

import commonEn from './locales/en/common.json';
import commonDe from './locales/de/common.json';
import commonNlFr from './locales/nl_fr/common.json';
import commonNl from './locales/nl/common.json';

configStore.init({
  // @ts-ignore
  apiUrl: window.env?.PUBLIC_API_URL || 'https://graph.labio/graphql',
  googleMapsApiKey: 'AIzaSyCse7qev-sFB6qEHE-IRUHARCCxddBeL5M',
});

contentConfigStore.init({
  // @ts-ignore
  apiUrl: window.env?.PUBLIC_API_URL || 'https://graph.labio/graphql',
});

sessionStore.$config.listen((val, _oldVal, _key) => {
  const factory = new DefaultValidatorFactory();
  for (const [key, rules] of Object.entries(JSON.parse(val.validationConfig.rules))) {
    $ruleStore.setKey(key, factory.importRules(rules as unknown[]));
    console.log(rules, factory.importRules(rules as unknown[]));
  }
});

i18next.use(initReactI18next).init<any>({
  debug: true,
  react: {
    useSuspense: false,
  },
  resources: {
    en: { common: commonEn },
    de: { common: commonDe },
    nl: { common: commonNl },
    nl_fr: { common: commonNlFr },
  },
  // if you see an error like: "Argument of type 'DefaultTFuncReturn' is not assignable to parameter of type xyz"
  // set returnNull to false (and also in the i18next.d.ts options)
  // returnNull: false,
});


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
