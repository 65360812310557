import { Link, Outlet } from 'react-router-dom'

export const Showcase = () => {
  return (
    <div style={{display: 'grid', gridTemplateColumns: '30% 1fr'}}>
      <ul>
        <li><Link to='/showcase'>Home</Link></li>
        <li><Link to='/showcase/address'>Adresse</Link></li>
        <li><Link to='/showcase/block'>Block</Link></li>
      </ul>
      <div><Outlet /></div>
    </div>
  )
}