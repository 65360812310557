import { Page } from '@/types'
import { useIsPageAllowed } from '../hooks/use-is-page-allowed';
import { PropsWithChildren, ReactElement } from 'react';

type Props = {
  page: Page;
  fallback: ReactElement;
}
export const PageGuard = (props: PropsWithChildren<Props>) => {
  const isAllowed = useIsPageAllowed(props.page);

  if(!isAllowed) return props.fallback || <></>;

  return <>{props.children}</>

}