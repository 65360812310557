import { configStore, salonStore, sessionStore } from '@lws/lib-checkout';
import { Modal, SecondaryButton } from '@lws/react-ui';
import { APIProvider } from '@vis.gl/react-google-maps';
import { useState } from 'react';
import { SalonSearchField } from './salon-search';
import { SalonResults } from './salon-results';
import { SalonChoice } from './salon-choice';
import styles from './salon-finder.module.scss';
import { Icon, IconComponent } from '../icons/icon';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Heading } from '../layout/heading';

export const SalonFinder = withTranslation('common')((props: WithTranslation) => {
  const { t } = props;
  const [isOpen, setOpen] = useState(false);
  const { salonId, saveSalon } = sessionStore.salon.useCheckoutSalon();

  return (
    <>
      <Heading title={t('checkout.salon_finder.headline')} level={3} icon={Icon.SALONPIN} onClick={() => setOpen(true)} className={styles.heading}/>
      <p>{t('checkout.review.salon_finder.description')}</p>
      <div className={styles.finder}>
        <Modal open={isOpen} setOpen={setOpen} minZIndex={101}>
          <APIProvider apiKey={configStore.$config.get().googleMapsApiKey}>
            <SalonSearchField isOpen={isOpen} onSearch={salonStore.searchSalons} />
          </APIProvider>
          <SalonResults setSalon={id => { saveSalon(id); setOpen(false); }} choice={salonId}/>
        </Modal>
        { salonId ?
          <button
            className={styles.primary}
            onClick={() => setOpen(true)}>
            <SalonChoice salonId={salonId} remove={() => saveSalon(undefined)} />
            <IconComponent className={styles.icon} icon={Icon.EDIT}/>
          </button>
          :
          <SecondaryButton className={styles.secondary} onClick={() => setOpen(true)}>{t('checkout.review.button.choose_salon')}</SecondaryButton> }
      </div>
    </>
  );
});
