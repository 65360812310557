import { BaseUpdateSession, UpdateSessionResult } from './base-update-session';

type Input = {
  sessionId: string;
  email: string;
}

export class SetGuestEmail extends BaseUpdateSession {
  constructor(public variables: Input) {
    super()
  }
  public readonly query: string = `
    mutation Mutation($sessionId: String, $email: String) {
      checkout {
        setGuestEmail(sessionId: $sessionId, email: $email) {
          ${this.getPartialSessionResponse()}
        }
      }
    }
  `;
  
    protected transformResponse(responseData: any) {
      return super.transformResponse(responseData, 'setGuestEmail')
    }
}