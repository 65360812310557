import React, { PropsWithChildren } from 'react';
import styles from './tabs.module.scss';

export const Tabs = (props: PropsWithChildren) => {
  return (
    <div className={styles['tabs']}>
      {props.children}
    </div>
  );
};
