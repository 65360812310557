import { BaseReviewLayout, ReviewLayout } from '@/components/review';
import { Page, sessionStore } from '@lws/lib-checkout';
import { events } from '@lws/lib-track';

export const Review = sessionStore.withPageGuard(Page.REVIEW, <>NOT ALLOWED</>)(() => {

  sessionStore.tracking.useTrack(new events.PwaPageView({
    pageType: 'checkout-review',
    path: '/review',
    referrer: window.document.referrer,
    category: 'checkout',
    title: 'Review',
  }))

  const { session } = sessionStore.useSession();
  const { billing, shipping } = sessionStore.address.useAddresses();

  if(!session || !billing || !shipping) return <></>;


  return (
    <>
      <BaseReviewLayout>
        <ReviewLayout/>
      </BaseReviewLayout>
    </>
  );
});
