export interface CartIconProps extends React.SVGAttributes<SVGSVGElement>{
}


export const CartIcon = (props: CartIconProps) => {
  return (
    <svg {...props} id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
      <path d="M98.27,18.09c-.47-.57-1.18-.91-1.92-.91H20.1c-.36-2.52-.59-4.17-.62-4.49C18.49,2.08,13.05.5,9.9.5H3.65C2.28.5,1.17,1.61,1.17,2.98s1.11,2.48,2.48,2.48h6.25c2.6,0,4.16,2.59,4.64,7.69.13,1.43,3.8,26.46,7.38,50.75l.82,5.59c.93,6.37,4.12,9.56,9.73,9.76,1.34.05,7.39.06,14.86.06,14.3,0,33.77-.06,35-.06,1.37,0,2.48-1.12,2.47-2.49,0-1.37-1.11-2.47-2.48-2.47h0c-.46,0-45.86.14-49.66,0-2.22-.08-4.27-.56-5-5.53l-.82-5.6c-.03-.23-.07-.49-.11-.74h61.58c1.18,0,2.2-.83,2.43-1.99l8.06-40.31c.15-.73-.04-1.48-.51-2.06ZM86.26,57.49H25.99c-1.51-10.25-3.75-25.53-5.17-35.35h72.51l-7.07,35.35Z"/>
      <path d="M30.09,99.5c-4.85,0-8.79-3.94-8.79-8.79s3.94-8.79,8.79-8.79,8.79,3.94,8.79,8.79-3.94,8.79-8.79,8.79ZM30.09,86.88c-2.11,0-3.83,1.72-3.83,3.83s1.72,3.83,3.83,3.83,3.83-1.72,3.83-3.83-1.72-3.83-3.83-3.83Z"/>
      <path d="M70.99,99.5c-4.85,0-8.79-3.94-8.79-8.79s3.94-8.79,8.79-8.79,8.79,3.94,8.79,8.79-3.94,8.79-8.79,8.79ZM70.99,86.88c-2.11,0-3.83,1.72-3.83,3.83s1.72,3.83,3.83,3.83,3.83-1.72,3.83-3.83-1.72-3.83-3.83-3.83Z"/>
    </svg>
  );
};
