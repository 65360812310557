import { notificationStore, sessionStore } from '@lws/lib-checkout';
import { Fragment, useEffect, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { Identity } from '../components/ui/header/identity';
import styles from './base.module.scss';
import { Footer } from '@/components/ui/footer/footer';
import { debugStore } from '@lws/lib-checkout';
import { NotificationContainer } from '@/components/notification';
import { useTranslation } from 'react-i18next';

const Debug = debugStore.Debug;

export const BaseLayout = () => {
  const [currentLang, setCurrentLang] = useState<string>('');
  const { sessionId } = useParams();
  const info = sessionStore.useSession();
  const { session, setId, config } = info;
  const { notifications, notify } = notificationStore.useNotification();
  const { i18n } = useTranslation();
  useEffect(() => {
    if(session.id !== sessionId) {
      setId(sessionId);
    }
  }, [sessionId, session]);

  useEffect(() => {
    if(info?.config?.frontend?.language) {
      const lang = info?.config?.frontend?.language;
      if (lang != i18n.language) {
        i18n.changeLanguage(lang).then(() => {
          setCurrentLang(lang);
        })
        
      }
    }
  }, [info]);

  if(!session) return <>LOADING</>;

  return (
    <Fragment key={currentLang}>
      <header>
        <Identity></Identity>
      </header>
      <main className={`${styles['main']} ${styles['page-width']}`}>
        <Outlet />
      </main>
      <Footer></Footer>
      <NotificationContainer />
      <Debug>
        <button onClick={() => {
          notify({
            message: 'hello',
            actions: [
              {buttonLabel: 'OK1', type: notificationStore.NotificationActionType.TO_SHOP, buttonProps: {primary: true}},
              {buttonLabel: 'OK2', type: notificationStore.NotificationActionType.TO_SHOP},
            ],
            disableDelay: true,
          })
        }}>notify</button>
        <pre style={{maxWidth: '100vw', overflowX: 'auto'}}>{JSON.stringify({info, notifications, i18n}, null, 2)}</pre>

      </Debug>
    </Fragment>
  );
};
