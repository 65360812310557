import { atom, computed, map } from 'nanostores';
import { $config, $session, $sessionId } from '../store';
import { useStore } from '@nanostores/react';
import { SetPaymentMethod } from '@/pre-defined-queries/set-payment-method';
import { client } from '@/client';
import { handleSessionResponse } from '../util/handle-session-response';
import { IAuthorizePaymentTransaction, ICapturePaymentTransaction, IPaymentMethod, IPaymentMethodTransaction } from '../../../types/index.js';

const setPaymentMethod = async(sessionId: string, paymentInformation: { code: string}) => {
  const result = await client.exec(new SetPaymentMethod({sessionId, paymentInformation}));
  handleSessionResponse(result);
}

export const usePaymentMethod = () => {
  const paymentMethod = useStore($paymentMethod);
  const transaction = useStore($paymentMethodTransaction);
  const authorizeTransaction = useStore($paymentAuthorizeTransaction);
  const sessionId = $sessionId.get()!;
  return {
    paymentMethod,
    transaction,
    authorizeTransaction,
    setPaymentMethodCode: (code: string) => setPaymentMethod(sessionId, { code }),
  }
}

export const $paymentMethodTransaction = computed($session, (session): IPaymentMethodTransaction | undefined => {
  if(!session.transactions) return;
  const transaction = session.transactions.find(t => t.__typename === 'PaymentTransaction') as IPaymentMethodTransaction | undefined;
  return transaction
});

export const $paymentAuthorizeTransaction = computed($session, (session): IAuthorizePaymentTransaction | undefined => {
  if(!session.transactions) return;
  const transaction = session.transactions.find(t => t.__typename === 'AuthorizePaymentTransaction') as IAuthorizePaymentTransaction | undefined;
  return transaction
});

const $paymentMethod = map<IPaymentMethod>();

const updateMethod = () => {
  const trx = $paymentMethodTransaction.get();
  const code = trx?.code
  const paymentMethods = $config.get().paymentMethods;
  const pm = paymentMethods?.find(m => m.code === code);
  if(pm !== $paymentMethod.get()) $paymentMethod.set(pm!);
}

$paymentMethodTransaction.subscribe(updateMethod);
$config.subscribe(updateMethod);