import { notificationStore } from '@lws/lib-checkout';
import { Notification } from './notification';

import styles from './notification.module.scss';

export const NotificationContainer = () => {
  const { notifications } = notificationStore.useNotification();

  return (
    <>
      <ul className={styles.notificationContainer}>
        {notifications.map(n => <Notification key={n.id} notification={n} />)}
      </ul>
    </>
  );
};
