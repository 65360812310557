import { useValidationIssues } from '@lws/react-ui';
import { useTranslation } from 'react-i18next';
import styles from './validation-messages.module.scss';


export const ValidationMessages = ({ name }: {name: string}) => {
  const { t } = useTranslation('common');
  const issues = useValidationIssues(name);
  if (issues.length < 1) {
    return <></>;
  }
  return (
    <ul className={styles.validationMessages}>
      {issues.map(issue => <li key={issue.message} className={styles.validationMessage}>{t(issue.message)}</li>)}
    </ul>
  );
};
