export interface InvisibleIconProps extends React.SVGAttributes<SVGSVGElement>{
}


export const InvisibleIcon = (props: InvisibleIconProps) => {
  return (
    <svg {...props} id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
      <g>
        <path d="M99.43,48.67c-.5-3.83-7.04-13.31-9.66-16.5C69.11,6.92,32.99,5.95,11.48,30.71c-3,3.45-10.35,13.68-10.91,17.96-.09.72-.11,1.57,0,2.32,1.07,3.46,2.9,6.7,4.82,9.75,22.07,35.13,67.28,35.01,89.29-.12,1.89-3.02,4.59-8.57,4.75-9.64.16-1.06,0-2.32,0-2.32ZM93.52,50.84c-.14.93-2.98,5.76-4.63,8.39-19.15,30.57-58.5,30.68-77.71.1-1.67-2.66-3.76-5.48-4.7-8.49-.09-.65-.08-1.39,0-2.02.48-3.73,7.38-12.63,9.99-15.63,18.72-21.55,50.15-20.71,68.14,1.27,2.28,2.78,8.47,11.03,8.91,14.36,0,0,.14,1.09,0,2.02Z"/>
        <path d="M49.99,26.68c-12.74,0-23.07,10.33-23.07,23.07s10.33,23.07,23.07,23.07,23.07-10.33,23.07-23.07-10.33-23.07-23.07-23.07ZM49.99,67.46c-9.74,0-17.63-7.89-17.63-17.63s7.89-17.63,17.63-17.63,17.63,7.89,17.63,17.63-7.89,17.63-17.63,17.63Z"/>
      </g>
      <path d="M3.29,99.36c-.71,0-1.43-.27-1.97-.82-1.09-1.09-1.09-2.86,0-3.95L94.73,1.17c1.09-1.09,2.86-1.09,3.95,0,1.09,1.09,1.09,2.86,0,3.95L5.27,98.54c-.55.55-1.26.82-1.97.82Z"/>
    </svg>
  );
};
