import styles from './footer.module.scss';
import { FooterNavi } from './footer-navigation';
import { FooterLinks } from './footer-links';

export const Footer = () => {

  return (
    <div className={styles.pageWidth}>
      <FooterNavi />
      <FooterLinks />
    </div>
  );
};
