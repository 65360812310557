import { UpdateSessionResult } from '@/pre-defined-queries/base-update-session';
import { RedirectAction } from '@/action';
import { $config, $session } from '../store';
import { configStore } from '@/store';

const isRedirectAction = (action: any): action is RedirectAction => {
  return action.__typename === 'CheckoutRedirectAction';
}

export const handleSessionResponse = (info: UpdateSessionResult | undefined) => {
  if(!info) return;
  const { session, actions, config } = info;
  $session.set(session);
  $config.set(config);

  for(const action of actions) {
    if(isRedirectAction(action)) {
      const targetUrl = new URL(action.target);
      const navigate = configStore.getNavigateCallback();
      navigate(targetUrl);
    }
  }
}