import { PropsWithChildren } from 'react';
import styles from './method-list.module.scss';
import { IPaymentMethod } from '@lws/lib-checkout';
import { PaymentMethod } from './method';

export interface PaymentMethodListProps {
  paymentMethods : IPaymentMethod[]
  paymentMethod : IPaymentMethod
}

export const PaymentMethodList = (props: PropsWithChildren<PaymentMethodListProps>) => {
  return (
    <div className={styles.radioWrapper}>
      {props.paymentMethods.map(method => <><PaymentMethod currentMethodCode={props.paymentMethod?.code} key={method.code} method={method} /><hr className={styles.hr}/></>)}
    </div>
  );
};
