import { WithTranslation, withTranslation } from 'react-i18next';
import { PAGE_PATH } from '@/config/urls';
import styles from './bar-element.module.scss';
import { Link, useParams } from 'react-router-dom';
import { Page, sessionStore } from '@lws/lib-checkout';
import { Icon, IconComponent } from '../icons/icon';

export interface ProgressBarElementProps extends WithTranslation {
  isCurrent: boolean;
  step: Page;
}

export const ProgressBarElement = withTranslation('common')(({ t, isCurrent, step }: ProgressBarElementProps) => {
  const isAllowed = sessionStore.useIsPageAllowed(step);
  const { sessionId } = useParams();
  const linkTarget = '/' + [sessionId, PAGE_PATH[step]].filter(Boolean).join('');
  let stepIcon : Icon | undefined;

  switch(step) {
  case Page.USER_INFO:
    stepIcon = Icon.USER;
    break;
  case Page.ADDRESS:
    stepIcon = Icon.HOUSE;
    break;
  case Page.PAYMENT:
    stepIcon = Icon.CARD;
    break;
  case Page.REVIEW:
    stepIcon = Icon.REVIEW;
  }

  return (
    <>
      <li className={(isCurrent ? `${styles.active} ${styles.barElement} `: styles.barElement) + (isAllowed ? '' : (' ' + styles.disabled))}>
        <Link to={linkTarget} onClick={(e) => {
          if(!isAllowed) {
            e.preventDefault();
            e.stopPropagation();
          }
        }}>
          <IconComponent icon={stepIcon}/>
          <span>{t(`checkout.step.${step}`)}</span>
        </Link>
      </li>
      <div className={styles.spacer}></div>
    </>
  );
});


