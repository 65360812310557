import { salonStore } from '@lws/lib-checkout';

export interface SalonChoiceProps {
  salonId: string;
  remove: () => void;
}

export const SalonChoice = (props: SalonChoiceProps) => {

  const salon = salonStore.useSalon(props.salonId);

  return (
    <div>
      <span style={{fontWeight: 'bold'}}>{salon?.name} | </span>
      {salon?.owner.map( o => {
        return <span>{o.firstname} {o.lastname}, </span>;
      })
      }

      <span>{salon?.location.postcode} {salon?.location.city}</span>
    </div>
  );
};
