import { client } from '@/client';
import { $salons } from '../store';
import { GetSalon } from '@/pre-defined-queries/get-salon';

export async function loadSalon(salonId: string) {
  const result = await client.exec(new GetSalon({ salonId }));
  if (result) {
    $salons.setKey(salonId, result);
  }
}
