import { BrowserRouter, useNavigate } from 'react-router-dom';
import { CheckoutRoutes } from '../../pages/routes';

import { configStore, debugStore } from '@lws/lib-checkout';
import { useEffect } from 'react';
import { DefaultValidatorFactory } from '@lws/lib-validate';
import { Validated } from '@lws/react-ui';
import { ErrorBoundary } from './error-boundary';

const DebugTrigger = debugStore.DebugTrigger;

const NavigateCallback = () => {
  const navigate = useNavigate();
  useEffect(() => {
    configStore.setNavigateCallback((target) => {
      const targetUrl = new URL(target);
      if(window.location.origin === targetUrl.origin) {
        const pathName = `${targetUrl.pathname}${targetUrl.search}`;
        navigate(pathName);
        return;
      }

      window.location.replace(targetUrl);
    });
  }, [navigate]);
  return <></>;
};

const App = () => {

  return (
    <ErrorBoundary>
      <BrowserRouter>
        <Validated name='checkout' validatorFactory={new DefaultValidatorFactory()}>
          <CheckoutRoutes />
          <DebugTrigger />
          <NavigateCallback />
        </Validated>
      </BrowserRouter>
    </ErrorBoundary>
  );
};

export default App;
