import { computed } from 'nanostores';
import { $session } from '../store';
import { useStore } from '@nanostores/react';
import { IShippingMethod, IShippingMethodTransaction } from '@/types';
export const useShippingMethodMethod = () => {
  const shippingMethod = useStore($shippingMethod);
  return {
    shippingMethod,
  }
}

export const $shippingMethod = computed($session, (session): IShippingMethod | undefined => {
  if(!session.transactions) return;
  const transaction = session.transactions.find(t => t.__typename === 'ShippingMethodTransaction') as IShippingMethodTransaction | undefined;
  if(!transaction) return;
  return transaction.shippingMethod;
});
