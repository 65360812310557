import { sessionStore } from '@lws/lib-checkout';
import { PropsWithChildren, useState } from 'react';
import { ContentModal } from '../ui/content-modal';
import styles from './legal.module.scss';
import { Trans, useTranslation } from 'react-i18next';



const LegalEntry = ({subSystem, slug}: {subSystem: string, slug: string}) => {
  const [open, setOpen] = useState<boolean>(false);
  const {t} = useTranslation('common')
  return (
    <>
      <p onClick={() => setOpen(true)} className={styles.trigger}>
        <Trans
          i18nKey={`legal.trigger.${slug}`}
          ns='common'
          components={{a: <a />}}
        />
      </p>
      <ContentModal {...{open, setOpen, subSystem, slug}} />
    </>
  );
};

export const Legal = () => {
  const { session, config } = sessionStore.useSession();
  if (!config || !session) return <></>;
  return (
    <>
      <ul className={styles['legal']}>
        {config.frontend.legalBlocks.map(slug => <li key={slug}><LegalEntry subSystem={session.subSystem} slug={slug} /></li>)}
      </ul>
    </>
  );
};
