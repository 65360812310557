import { CheckoutTotal, sessionStore } from '@lws/lib-checkout';
import { WithTranslation, withTranslation } from 'react-i18next';
import styles from './totals.module.scss';
import { Price } from '../ui/price';

const Total = withTranslation('common') ((props: {total?: CheckoutTotal} & WithTranslation) => {
  if(!props.total) return <></>;

  return (

    <div className={styles['li-content']}>
      <div>{props.t(`checkout.review.${props.total.name}`)}</div>
      <Price price={props.total.value}/>
    </div>
  );
});

export const Totals = () => {
  const { session, config } = sessionStore.useSession();
  const displayTotals = config.frontend.totalCodes || [
    'item_total',
    'shipping_total',
    'grand_total',
  ];

  const totals = session?.totals || [];
  return (
    <ul className={styles['totals']}>
      {displayTotals.map(displayTotal => (
        <li className={styles['total']}>
          <Total key={displayTotal} total={totals.find(t => t.name === (displayTotal))} />
        </li>
      ))}
    </ul>

  );
};
