import { useStore } from '@nanostores/react';
import { $salons } from '../store';
import { loadSalon } from '../actions/load-salon';

export function useSalon(salonId: string) {
  const salons = useStore($salons, { keys: [salonId] });
  if (salons[salonId] === undefined) {
    loadSalon(salonId);
  }
  return salons[salonId];
}
