import { computed } from 'nanostores';
import { $session, $sessionId } from '..';
import { useStore } from '@nanostores/react';
import { client } from '../../../client';
import { SetGuestEmail } from '../../../pre-defined-queries/set-guest-email';
import { handleSessionResponse } from '../util/handle-session-response';
import { ICustomerInfoTransaction, IGuestInfoTransaction } from '../../../types';
import { CheckoutCustomerEmail } from '../../../pre-defined-queries/check-customer-email';
import { LoginCustomer } from '../../../pre-defined-queries/login-customer';
import { LogoutCustomer } from '../../../pre-defined-queries/logout-customer';
import { ResetCustomerPassword } from '@/pre-defined-queries/reset-customer-passowrd';
import { notify } from '@/store/notification';

const setGuestEmail = async (sessionId: string, email: string) => {
  const result = await client.exec(new SetGuestEmail({sessionId, email}));
  handleSessionResponse(result);
};

const checkEmail = async(sessionId: string, email: string) => {
  const result = await client.exec(new CheckoutCustomerEmail({sessionId, email}));
  return result;
};

const resetPassword = async(sessionId: string, email: string) => {
  const result = await client.exec(new ResetCustomerPassword({sessionId, email}));
  notify({
    message: 'checkout.customer.password-reset.success',
    messageData: { email },
    actions: [],
  });
  return result;
};

const login = async(sessionId: string, email: string, password: string) => {
  const result = await client.exec(new LoginCustomer({sessionId, email, password}));
  handleSessionResponse(result);
};

const logout = async(sessionId: string) => {
  const result = await client.exec(new LogoutCustomer({sessionId}));
  handleSessionResponse(result);
};

export const useUserInfo = () => {
  const userInfo = useStore($userInfo);
  const sessionId = $sessionId.get();
  return {
    guest: userInfo.guest,
    email: userInfo.guest ? userInfo.email : undefined,
    customer: userInfo.guest === false ? userInfo.customer : undefined,
    setGuestEmail: (email: string) => setGuestEmail(sessionId!, email),
    checkEmail: (email: string) => checkEmail(sessionId!, email),
    resetPassword: (email: string) => resetPassword(sessionId!, email),
    login: (email: string, password: string) => login(sessionId!, email, password),
    logout: () => logout(sessionId!),
  };
};

type UserInfoStoreType = IGuestInfoTransaction | ICustomerInfoTransaction | { guest: undefined }

export const $userInfo = computed($session, (session): UserInfoStoreType => {
  if(!session.transactions) return { guest: undefined };
  const transaction = session.transactions.find(t => t.__typename === 'GuestInfoTransaction' || t.__typename === 'CustomerInfoTransaction');
  if(transaction) return transaction as IGuestInfoTransaction | ICustomerInfoTransaction;

  return { guest: undefined };
});
