import { AddressType, IAddress, ICheckoutConfig, sessionStore } from '@lws/lib-checkout';
import { EditAddress } from '../ui/address/edit';
import { useMemo } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Link } from '../ui/link';
import styles from './address-creation.module.scss';

type AddressProps = {
  currentAddress?: IAddress;
  hasSavedAddresses: boolean;
  enableSelectSavedAddress: () => void;
  namePrefix?: string;
  setAddressType?: (type: AddressType) => void;
  addressTypes?: Array<AddressType>;
  config: ICheckoutConfig;
}

export const AddressCreation = withTranslation('common')((props: AddressProps & WithTranslation) => {
  const { t } = props;
  const { customer } = sessionStore.userInfo.useUserInfo();
  const currentAddress = useMemo(() => {
    if(!customer) return props.currentAddress;
    if(props.currentAddress) return props.currentAddress;

    return {
      firstname: customer.firstname,
      lastname: customer.lastname,
    };

  }, [props.currentAddress, customer]);
  return (
    <div>
      <EditAddress
        namePrefix={props.namePrefix}
        setAddressType={props.setAddressType}
        addressTypes={props.addressTypes}
        config={props.config}
        address={currentAddress || {}}
      />
      {props.hasSavedAddresses ? (
        <Link className={styles.link} onClick={() => props.enableSelectSavedAddress()}>{t('checkout.address.button.saved.addresses')}</Link>
      ): ''}
    </div>
  );
});
