import { Tracker, events } from '@lws/lib-track';
import { atom } from 'nanostores';
import { $config } from '../store';
import { useStore } from '@nanostores/react';
import { useEffect, useRef } from 'react';

const tracker = new Tracker();

export const $tracking = atom<Tracker>(tracker);

export const useTrack = (event: events.AbstractEvent) => {
  const once = useRef<boolean>(false);
  const tracker = useTracker();
  useEffect(() => {
    if(once.current) return;
    once.current = true;
    tracker.track(event);
  });
};

export const useTracker = () => {
  return useStore($tracking);
};

export function setCookie(name: string, val: string) {
  const date = new Date();
  const value = val;

  date.setTime(date.getTime() + (7 * 24 * 60 * 60 * 1000));
  document.cookie = `${name}=${value}; expires=${date.toUTCString()}; path=/`;
}

export const initTracking = (matomoUrl: string, containerId: string, stage: string = 'live', consentString?: string) => {
  if(consentString) setCookie('mtm-consent', consentString);
  tracker.setup(matomoUrl, containerId, stage);
};

$config.subscribe((config) => {
  if(config.tracking) {
    initTracking(config.tracking.matomoUrl, config.tracking.containerId, config.tracking.stage, config.tracking.consentString);
  }
});
