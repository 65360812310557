import { PropsWithChildren } from 'react';
import { Box } from '../ui/layout/box';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Heading } from '../ui/layout/heading';
import { Items } from './items';
import { Helmet } from 'react-helmet';
import { ReviewAddresses } from './addresses';
import { Totals } from './totals';
import { Payment } from './payment';
import { Legal } from './legal';

import styles from './review-layout.module.scss';
import { Shipping } from './shipping';
import { Account } from './account';
import { SalonFinderLayout } from './salon-finder-layout';
import { BuyButton } from './buy-button/base';

export const ReviewLayout = withTranslation('common') ((props: PropsWithChildren & WithTranslation) => {
  const { t } = props;

  return (
    <>
      <Helmet>
        <title>{t('checkout.step.review')}</title>
      </Helmet>
      <div className={styles.box}>
        <Box className={styles.header}>
          <Heading level={2} title={t('checkout.review.overview')} className={styles.heading}/>
          <SalonFinderLayout/>
        </Box>
        <Box className={styles.items}>
          <Items/>
        </Box>
        <Box className={styles.addresses}>
          <ReviewAddresses />
          <Account />
          <Payment />
          <Shipping />
        </Box>
        <Box className={styles.totals}>
          <Totals/>
          <Legal/>
          <Payment redirectInfo={true} />
        </Box>
      </div>
      <BuyButton />
    </>
  );
});
