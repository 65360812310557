import { Fragment, useState } from 'react';
import styles from './footer-links.module.scss';
import { sessionStore } from '@lws/lib-checkout';
import { Link } from '../link';
import { ContentModal } from '../content-modal';
import { WithTranslation, withTranslation } from 'react-i18next';

const FooterLinkModal = (props: {name: string, subSystem: string, block: string}) => {
  const [ open, setOpen ] = useState<boolean>(false);
  return (
    <>
      <Link onClick={() => setOpen(true)} className={styles.footerElement}>{props.name}</Link>
      <ContentModal
        open={open}
        setOpen={setOpen}
        title={props.name}
        subSystem={props.subSystem}
        slug={props.block!}
      />
    </>
  );
};

export const FooterLinks = withTranslation('common')((props: WithTranslation) => {
  const { t } = props;
  const { config, session } = sessionStore.useSession();
  const footerLinks = config?.frontend?.footerLinks || [];

  return (
    <>
      <div className={styles.footerLinks}>
        {footerLinks.map(footerLink => (
          <Fragment key={footerLink.name}>
            { footerLink.target != null ? (
              <Link href={footerLink.target} className={styles.footerElement}>{footerLink.name}</Link>
            ) : ''}

            { footerLink.block != null ? (
              <FooterLinkModal name={footerLink.name} block={footerLink.block!} subSystem={session.subSystem} />
            ) : ''}
          </Fragment>
        ))}
      </div>
      <p className={styles.credits}>{t('checkout.footer.credits')}</p>
    </>
  );
});
