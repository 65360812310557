import { sessionStore } from '@lws/lib-checkout';
import { Heading } from '../ui/layout/heading';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Price } from '../ui/price';
import styles from './shipping.module.scss';

export const Shipping = withTranslation('common') ((props: WithTranslation) => {
  const { shippingMethod } = sessionStore.shipping.useShippingMethodMethod();
  const { t } = props;
  return (
    <div className={styles.shipping}>
      <Heading level={3} title={props.t('checkout.review.shipping')}></Heading>
      <span>{t('checkout.shipping.time')}</span>
      <span>{t('checkout.shipping.fee')} <Price price={shippingMethod.price}/></span>
    </div>
  );
});
