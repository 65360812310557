import { useStore } from '@nanostores/react'
import { $config, $session, $sessionId, setSessionId } from '..'

export const useSession = () => {
  const sessionId = useStore($sessionId);
  const session = useStore($session);
  const config = useStore($config);

  return {
    session,
    config,
    sessionId,
    setId: setSessionId,
  };
}
