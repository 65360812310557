export interface UserIconProps extends React.SVGAttributes<SVGSVGElement>{
}


export const UserIcon = (props: UserIconProps) => {
  return (
    <svg {...props} id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
      <path d="M50.12.5c-14.4,0-26.06,11.66-26.06,26.06s11.66,26.06,26.06,26.06,26.06-11.66,26.06-26.06S64.52.5,50.12.5ZM50.12,47.13c-11.36,0-20.56-9.21-20.56-20.56S38.76,6,50.12,6s20.56,9.21,20.56,20.56-9.21,20.56-20.56,20.56Z"/>
      <path d="M5.99,99.5h-.23c-1.49-.11-2.52-1.37-2.4-2.74,1.49-21.83,22.06-38.87,46.76-38.87s45.27,17.03,46.76,38.75c.11,1.49-1.03,2.63-2.4,2.74-1.49.11-3.13-1.03-3.24-2.4-1.37-19.09-19.16-33.45-41.11-33.45s-39.85,14.48-41.11,33.57c-.11,1.37-1.76,2.4-3.02,2.4Z"/>
    </svg>
  );
};
