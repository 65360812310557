import { PropsWithChildren } from 'react';
import styles from './button.module.scss';
import { Icon, IconComponent } from '../icons/icon';


export interface ButtonProps extends React.HTMLProps<HTMLButtonElement> {
  type?: 'submit' | 'reset' | 'button';
  title?: string;
  icon?: Icon;
  primary?: boolean;
}

export const Button = (props: PropsWithChildren<ButtonProps>) => {
  return (
    <div>
      {props.title ?
        <button {...props} type={props.type} className={`${props.primary ? styles['primary'] : ''} ${styles['title']}`}>
          <span>{props.title}</span>
        </button> :
        <button {...props} className={styles['edit']}>
          <IconComponent icon={props.icon}/>
        </button>}
    </div>
  );
};
