import { t } from 'i18next';
import { PropsWithChildren } from 'react';
import { Helmet } from 'react-helmet';
import { ProgressBar } from '../ui/progress/bar';
import { Page } from '@lws/lib-checkout';

export const BaseReviewLayout = (props: PropsWithChildren) => {
  return (
    <>
      <Helmet>
        <title>{t('Review')}</title>
      </Helmet>
      <ProgressBar step={Page.REVIEW} />
      {props.children}
    </>
  );
};
