import { PropsWithChildren } from 'react';
import { Helmet } from 'react-helmet';
import { ProgressBar } from '../ui/progress/bar';
import { Page } from '@lws/lib-checkout';
import { WithTranslation, withTranslation } from 'react-i18next';

export const UserInfoLayout = withTranslation('common')((props: PropsWithChildren & WithTranslation) => {
  const { t } = props;
  return (
    <>
      <Helmet>
        <title>{t('UserInfo')}</title>
      </Helmet>
      <ProgressBar step={Page.USER_INFO} />
      {props.children}
    </>
  );
});
