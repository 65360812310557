import { BaseCheckoutAction } from '../action';
import { ICheckoutSession } from '../types';
import { PreDefinedQuery } from './base';
import { BaseUpdateSession, UpdateSessionResult } from './base-update-session';

type Input = {
  sessionId: string;
}


export class LogoutCustomer extends BaseUpdateSession {
  constructor(public variables: Input) {
    super()
  }
  public readonly query: string = `
    mutation Mutation($sessionId: String) {
      checkout {
        logoutCustomer(sessionId: $sessionId) {
          ${this.getPartialSessionResponse()}
        }
      }
    }
  `;
  
    protected transformResponse(responseData: any) {
      return super.transformResponse(responseData, 'logoutCustomer')
    }
}