import { BaseCheckoutAction } from '../action';
import { ICheckoutSession } from '../types';
import { PreDefinedQuery } from './base';

type Input = {
  sessionId: string;
  email: string;
}

type Result = {
  hasAccount: boolean
}

export class CheckoutCustomerEmail extends PreDefinedQuery<Result> {
  constructor(public variables: Input) {
    super()
  }
  public readonly query: string = `
    mutation CheckCustomerEmail($sessionId: String, $email: String) {
      checkout {
        checkCustomerEmail(sessionId: $sessionId, email: $email) {
          hasAccount
        }
      }
    }
  `;

  protected transformResponse(responseData: any): Result {
    const hasAccount = !!responseData.data.checkout.checkCustomerEmail.hasAccount;

    return { hasAccount };
  }
}