import { IAddress } from '@lws/lib-checkout';
import React from 'react';
import styles from './display.module.scss';

export interface DisplayAddressProps {
  address: IAddress;
}

export const DisplayAddress: React.FC<DisplayAddressProps & React.HTMLAttributes<HTMLDivElement>> = (props) => {
  const { address, ...divProps } = props;
  return (
    <div {...divProps} className={`${styles.address} ${props.className}`}>
      <p>{address.prefix} {address.firstname} {address.lastname}</p>
      {[address.addr1, address.addr2, address.addr3].filter(Boolean).map(a => <p key={a}>{a}</p>)}
      <p>{address.country}-{address.postcode} {address.city}</p>
    </div>
  );
};
