import { Content } from '@lws/lib-content';
import { Modal } from '@lws/react-ui';
import { useEffect, useState } from 'react';
import { LoadingIndicator } from './loading-indicator';

type Props = {
  subSystem: string;
  slug: string;

  title?: string;
  open: boolean;
  setOpen: (v: boolean) => void;
}

export const ContentModal = ({open, setOpen, title, ...props}: Props) => {
  const [ renderContent, setRenderContent ] = useState<boolean>(false);

  useEffect(() => {
    if(open && !renderContent) setRenderContent(true);
  }, [ open ]);

  return (
    <Modal minZIndex={101} {...{open, setOpen, title}}>
      {renderContent ? (
        <Content.Block {...props} />
      ) : <LoadingIndicator />}
    </Modal>
  );

};
