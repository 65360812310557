export interface PostOfficeIconProps extends React.SVGAttributes<SVGSVGElement>{
}


export const PostOfficeIcon = (props: PostOfficeIconProps) => {
  return (
    <svg {...props} id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
      <circle cx="92.54" cy="69.92" r="1.93"/>
      <path d="M97.57,35.88h-3.09V2.43c0-1.07-.87-1.93-1.93-1.93H7.46c-1.07,0-1.93.87-1.93,1.93v33.45h-3.09c-1.07,0-1.93.87-1.93,1.93v7.73c0,1.07.87,1.93,1.93,1.93h3.09v40.41c-1.07,0-1.93.87-1.93,1.93v7.73c0,1.07.87,1.93,1.93,1.93h88.95c1.07,0,1.93-.87,1.93-1.93v-7.73c0-1.07-.87-1.93-1.93-1.93v-9.28c0-1.07-.87-1.93-1.93-1.93s-1.93.87-1.93,1.93v9.28h-42.04v-40.41h3.37v32.68c0,1.07.87,1.93,1.93,1.93h30.94c1.07,0,1.93-.87,1.93-1.93v-32.68h3.87v13.73c0,1.07.87,1.93,1.93,1.93s1.93-.87,1.93-1.93v-13.73h3.09c1.07,0,1.93-.87,1.93-1.93v-7.73c0-1.07-.87-1.93-1.93-1.93ZM9.39,4.37h81.21v31.52H9.39V4.37ZM13.26,47.49v40.41h-3.87v-40.41h3.87ZM13.26,95.63h-5.8v-3.87h5.8v3.87ZM17.63,47.99h26.07v47.15h-26.07v-47.15ZM92.04,92.27v2.87h-43.47v-2.87h43.47ZM82.37,77.73h-26.07v-29.74h26.07v29.74ZM95.13,43.12H4.87v-2.87h90.27v2.87Z"/>
      <circle cx="22.93" cy="71.66" r="1.93"/>
      <path d="M61.33,18.44c-2.05-.77-3.97-1.61-4.53-1.86-.37-.3-.55-.77-.48-1.27.05-.33.28-1.13,1.42-1.47,2.4-.73,4.61,1.04,4.67,1.09.81.68,2.03.58,2.72-.23.69-.81.6-2.03-.22-2.73-.15-.13-3.83-3.2-8.3-1.84-2.23.68-3.8,2.45-4.12,4.62-.3,2.04.58,4.01,2.28,5.13.09.06.18.11.27.15.1.04,2.37,1.07,4.94,2.03.76.29,2.52,1.1,2.27,2.49-.18,1-1.36,2.03-3.03,2.03s-3.3-.69-4.33-1.84c-.71-.8-1.93-.86-2.73-.15-.8.71-.86,1.93-.15,2.73,1.75,1.96,4.45,3.13,7.21,3.13,3.41,0,6.29-2.19,6.83-5.21.41-2.28-.61-5.26-4.72-6.8h0Z"/>
      <path d="M80.74,9.79h-9.98c-1.07,0-1.93.87-1.93,1.93s.87,1.93,1.93,1.93h3.04v14.86c0,1.07.87,1.93,1.93,1.93s1.93-.87,1.93-1.93v-14.86h3.08c1.07,0,1.93-.87,1.93-1.93s-.87-1.93-1.93-1.93Z"/>
      <path d="M24.82,9.78h-4.68c-1.08,0-1.93.89-1.93,1.93v16.82c0,1.07.87,1.93,1.93,1.93s1.93-.87,1.93-1.93v-5.38c.99,0,2.12-.01,2.75-.01,3.74,0,6.77-3,6.77-6.68s-3.04-6.68-6.77-6.68ZM24.82,19.27c-.63,0-1.75,0-2.73.01,0-1.02-.01-2.18-.01-2.82,0-.55,0-1.75,0-2.81h2.74c1.58,0,2.91,1.29,2.91,2.81s-1.33,2.81-2.91,2.81Z"/>
      <path d="M41.69,9.83c-3.64,0-6.59,2.96-6.59,6.59v7.45c0,3.64,2.96,6.59,6.59,6.59s6.59-2.96,6.59-6.59v-7.45c0-3.64-2.96-6.59-6.59-6.59ZM44.42,23.88c0,1.5-1.22,2.73-2.73,2.73s-2.73-1.22-2.73-2.73v-7.45c0-1.5,1.22-2.73,2.73-2.73s2.73,1.22,2.73,2.73v7.45Z"/>
    </svg>
  );
};
