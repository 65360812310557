import { PropsWithChildren } from 'react';
import styles from './radio.module.scss';
import { Checkbox } from './checkbox-icon';




export interface RadioProps extends PropsWithChildren<React.InputHTMLAttributes<HTMLInputElement>> {
}

export const Radio = (props: RadioProps) => {
  const {children, ...inputProps} = props;
  const defaultClass = styles['label'];
  const className = props.className ? `${props.className} ${defaultClass}` : defaultClass;
  return (
    <label  className={className}>
      <input {...inputProps} type='radio' className={styles['radio']} />
      <Checkbox className={styles['checkbox']}/>
      {children}
    </label>
  );
};

