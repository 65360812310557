import { sessionStore } from '@lws/lib-checkout';
import { Heading } from '../ui/layout/heading';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Button } from '../ui/layout/button';
import { Icon } from '../ui/icons/icon';
import styles from './account.module.scss';
import { useNavigate } from 'react-router-dom';


export const Account = withTranslation('common') ((props: WithTranslation) => {
  const info = sessionStore.userInfo.useUserInfo();
  const { t } = props;
  const navigate = useNavigate();
  const { sessionId } = sessionStore.useSession();

  return (
    <>
      <Heading level={3} title={props.t('checkout.review.account')} button={<Button onClick={() => navigate(`/${sessionId}/user-info`)} icon={Icon.EDIT}></Button>}></Heading>
      {info.guest ? (
        <p className={styles.infos}>{t('checkout.review.account.guest')}: {info.email}</p>
      ) : (
        <div className={styles.infos}>
          <p>{t('checkout.review.account.email')}: {info.customer?.email} </p>
          <p>{t('checkout.review.account.name')}: {info.customer?.firstname} {info.customer?.lastname}</p>
          <p>{t('checkout.review.account.customer_number')}: {info.customer?.customerNumber}</p>
        </div>
      )}
    </>
  );
});
