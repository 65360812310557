export interface HouseIconProps extends React.SVGAttributes<SVGSVGElement>{
}


export const HouseIcon = (props: HouseIconProps) => {
  return (
    <svg {...props} id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
      <path d="M37.73,76.83c-1.35,0-2.45,1.23-2.45,2.75s1.1,2.75,2.45,2.75h24.53c1.35,0,2.45-1.23,2.45-2.75s-1.1-2.75-2.45-2.75h-24.53Z"/>
      <path style={{fillRule: 'evenodd'}} d="M60.64,4.19c-6.24-4.93-15.04-4.93-21.28,0L7.47,29.37c-4.12,3.26-6.53,8.22-6.53,13.48v39.48c0,9.48,7.69,17.17,17.17,17.17h63.78c9.48,0,17.17-7.69,17.17-17.17v-39.48c0-5.26-2.41-10.22-6.53-13.48L60.64,4.19ZM42.4,8.54c4.46-3.52,10.74-3.52,15.2,0l31.89,25.18c2.95,2.33,4.17,5.37,4.17,9.13v39.48c0,6.77-4.99,11.77-11.77,11.77H18.11c-6.77,0-11.77-4.99-11.77-11.77v-39.48c0-3.75,1.22-6.8,4.17-9.13l31.89-25.18Z"/>
    </svg>
  );
};
