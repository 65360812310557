import { useState } from 'react';
import { Heading } from '../ui/layout/heading';
import { SalonFinder } from '../ui/salon-finder/salon-finder';
import { SalonChoice } from '../ui/salon-finder/salon-choice';
import { Salon } from '@lws/lib-checkout';
import { Icon } from '../ui/icons/icon';
import { WithTranslation, withTranslation } from 'react-i18next';

export const SalonFinderLayout = withTranslation('common')((props: WithTranslation) => {

  const { t } = props;
  const [salon, setSalon] = useState<Salon | undefined>();


  return (
    <>

      <SalonFinder />


      { salon ? <SalonChoice salonId={salon?.salon_id} remove={() => setSalon(undefined)} /> : <></> }

    </>
  );
});
