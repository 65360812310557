import { sessionStore } from '@lws/lib-checkout';
import { Heading } from '../ui/layout/heading';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Button } from '../ui/layout/button';
import { Icon } from '../ui/icons/icon';
import styles from './payment.module.scss';
import { useNavigate } from 'react-router-dom';

export interface Props extends WithTranslation {
  redirectInfo?: boolean
}

export const Payment = withTranslation('common') ((props: Props) => {
  const { paymentMethod } = sessionStore.payment.usePaymentMethod();
  const { t } = props;
  const navigate = useNavigate();
  const { sessionId } = sessionStore.useSession();

  if(props.redirectInfo) {
    return <p className={styles.redirect}>{t(`checkout.payment.info.${paymentMethod.code}`)}</p>;
  }

  return (
    <>
      <Heading level={3} title={props.t('checkout.review.payment')} button={<Button onClick={() => navigate(`/${sessionId}/payment`)} icon={Icon.EDIT}></Button>}></Heading>
      <div className={styles.payment}>
        <span>{t(`checkout.payment.method.${paymentMethod.code}`)}</span>
        <img src={t(`checkout.payment.image.${paymentMethod.code}`)}/>
      </div>
    </>
  );
});
