import { PrimaryButton } from '@lws/react-ui';
import styles from '../review-layout.module.scss';
import { sessionStore } from '@lws/lib-checkout';
import { useTranslation } from 'react-i18next';
import { ComputopPayPalButton } from './computop_paypal';
import { useState } from 'react';

const frontendIntegrationButtons: Record<string, React.FC | undefined> = {
  computop_paypal: ComputopPayPalButton,
};

export const BuyButton = () => {
  const { t } = useTranslation('common');
  const { place } = sessionStore.salesOrder.useSalesOrder();
  const { paymentMethod } = sessionStore.payment.usePaymentMethod();
  console.log(paymentMethod);
  const [click, setClicked] = useState(false);

  const Component = frontendIntegrationButtons[paymentMethod?.code];

  if(Component) return <Component />;

  return (
    <PrimaryButton className={styles.orderButton} disabled={click} onClick={() => {
      setClicked(true);
      place().then(() => setClicked(false)).catch(() => setClicked(false));
    }}>{t('checkout.review.button.pay_now')}
    </PrimaryButton>
  );
};
