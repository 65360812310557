/* eslint-disable indent */
import { configStore, notificationStore } from '../store';

type RecursivePartial = Array<string | Record<string, RecursivePartial>>;

export abstract class PreDefinedQuery<T> {
  public abstract readonly query: string;
  public abstract readonly variables: unknown;

  protected transformResponse(data: unknown): T {
    return data as T;
  }

  async exec(): Promise<T> {
    const response = await fetch(configStore.$config.get().apiUrl, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify({
        query: this.query,
        variables: this.variables,
      }),
    });

    const responseData = await response.json();

    if(responseData.errors) {

      for(const error of responseData.errors) {
        console.error(error)
        if(error.extensions.lws) {
          notificationStore.notify({
            actions: [],
            message: `error.lws.${error.message}`
          })
        } else {
          notificationStore.notify({
            actions: [],
            message: error.message
          })
        }
      }
    }

    return this.transformResponse(responseData);
  }

  getPartialConfigFields() {
    return [
      'allowedCountries',
      'allowedAddressTypes',
      'addressPrefix { show required allowedValues }',
      'paymentMethods { code name }',
      'frontend { language footerLinks { name block target } legalBlocks totalCodes }',
      'allowedPages { pages }',
      'validationConfig { rules }',
      'computopFrontend { currency paypalClientId }',
      'tracking { matomoUrl containerId stage consentString }',
    ].join('\n');
  }

  getPartialSessionResponse() {
    return `
      session {
        orderNumber
        successUrl
        cancelUrl
        quote {
          items {
            name
            sku
            price
            priceMode
            qty
            taxClass
            id
            additionalAttributes {
              key
              value
            }
          }
        }
        id
        ${this.getPartialTransactions()}
        subSystem
        totals {
          name
          value
        }
      }
      actions {
        ... on CheckoutRedirectAction {
          target
          __typename
        }
      }
      config {
        ${this.getPartialConfigFields()}
      }
    `;
  }

  protected _recursivePartials(fields: RecursivePartial): string {
    return fields.map(f => typeof f === 'string' ? f : Object.keys(f).map((key) => {
      return `${key} {
        ${(typeof f[key] === 'string' ? f[key] : this._recursivePartials(f[key]))}
      }`;
    })).join('\n');
  }


  getPartialTransactions() {
    const transaction = (name: string, fields: RecursivePartial) => `
      ... on ${name}Transaction {
        __typename
        id
        sessionId
        subSystem
        ${this._recursivePartials(fields)}
      }
    `;

    const adddressFields = [
      'prefix',
      'firstname',
      'lastname',
      'addr1',
      'addr2',
      'addr3',
      'postcode',
      'city',
      'country',
      'addressType',
    ];
    return `
      transactions {
          ${transaction('LastActivity', ['ts'])},
          ${transaction('Salon', ['salonId', 'referral'])},
          ${transaction('GuestInfo', ['email', 'guest'])},
          ${transaction('Payment', ['code', 'name'])},
          ${transaction('AuthorizePayment', ['code', 'status', 'redirectUri'])},
          ${transaction('CapturePayment', ['code', 'status'])},
          ${transaction('CustomerInfo', ['guest', {
            'customer': [
              'customerNumber',
              'email',
              'firstname',
              'lastname',
              {
                addresses: adddressFields,
                defaultBilling: adddressFields,
                defaultShipping: adddressFields,
              },
            ],
          }])},
          ${transaction('Addresses', [
            {
              billing: adddressFields,
              shipping: adddressFields,
              setAsDefault: ['billing', 'shipping']
            },
          ])}
          ${transaction('ShippingMethod', [
            {
              shippingMethod: [
                `
                ... on FlatrateShippingMethod {
                  code
                  price
                  priceMode
                  taxClass
                }
                `,
              ],
            },
          ])}
          ${transaction('Utm', [
            {
              utm: [ 'source','medium','term','content','campaign' ],
            },
          ])}
      }
    `;
  }
}
