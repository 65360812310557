import { AddressType, IAddress } from '@lws/lib-checkout';
import { DisplayAddress } from '../ui/address/display';
import { compareAddresses } from '@/util/compare-address';
import { Button } from '@lws/react-ui';
import { prefix } from '@/util/prefix';
import { Radio } from '../ui/form/radio';
import styles from './address-selection.module.scss';
import { WithTranslation, withTranslation } from 'react-i18next';

type AddressProps = {
  currentAddress?: IAddress;
  availableAddresses: Array<IAddress>;
  enableNewAddress: () => void;
  namePrefix?: string;
  setAddressType?: (type: AddressType) => void;
}

export const AddressSelection = withTranslation('common')((props: AddressProps & WithTranslation) => {
  const { t } = props;
  return (
    <>
      <ul className={styles.list}>
        {props.availableAddresses.map((addr, idx) => (
          <li key={idx}>
            <Radio
              required
              className={styles.radio}
              name={prefix('selected', props.namePrefix)}
              defaultChecked={compareAddresses(props.currentAddress, addr)}
              value={JSON.stringify(addr)}
              onChange={() => {
                if(props.setAddressType) props.setAddressType(addr.addressType || AddressType.HOME);
              }}>
              <DisplayAddress className={styles.address} address={addr} />
              <div className={styles.icon}></div>
            </Radio>
          </li>
        ))}
      </ul>
      <div className={styles.buttonWrapper}>
        <Button className={styles.button} onClick={() => props.enableNewAddress()}>{t('checkout.address.button.new.address')}</Button>
      </div>
    </>
  );
});
