import { BaseUpdateSession, UpdateSessionResult } from './base-update-session';

type Input = {
  sessionId: string;
  salonId?: string;
}

export class SetSalon extends BaseUpdateSession {

  constructor(public variables: Input) {
    super();
  }

  public readonly query: string = `
    mutation Mutation($sessionId: String!, $salonId: String) {
      checkout {
        setSalon(sessionId: $sessionId, salonId: $salonId) {
          ${this.getPartialSessionResponse()}
        }
      }
    }
  `;

  protected transformResponse(responseData: any) {
    return super.transformResponse(responseData, 'setSalon');
  }
}
