import { prefix } from '@/util/prefix';
import { AddressType, IAddress, ICheckoutConfig } from '@lws/lib-checkout';
import React, { useEffect, useState } from 'react';
import { Row } from '../layout/row';
import { Tab } from '../layout/tab';
import { Tabs } from '../layout/tabs';
import { Icon } from '../icons/icon';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Input, ValidatedInput } from '../form/input';
import { ValidationMessages } from '../form/validation-messages';
import styles from './edit.module.scss';
import { ValidatedDropDown } from '../form/drop-down';


export interface EditAddressProps{
  address: Partial<IAddress>
  addressTypes?: Array<AddressType>;
  namePrefix?: string;
  setAddressType?: (type: AddressType) => void;
  config: ICheckoutConfig;
}

export const EditAddress = withTranslation('common') ((props: EditAddressProps & React.HTMLAttributes<HTMLDivElement> & WithTranslation) => {
  const { address, namePrefix, config, t, addressTypes = [AddressType.HOME], ...divProps } = props;

  const typeSelection = addressTypes?.filter(t => t !== AddressType.HOME).length > 0;

  const [ currentType, setCurrentType ] = useState<AddressType>(address.addressType || AddressType.HOME);

  const prefixOptions = config.addressPrefix.allowedValues.map(v => ({label: v, value: v }));
  const countryOptions = config.allowedCountries.map(v => ({label: v, value: v }));
  useEffect(() => {
    if(props.setAddressType) props.setAddressType(currentType);
  }, [currentType]);

  return (
    <div {...divProps}>
      <div>
        {config.addressPrefix?.show ? (
          <Row split={[2, 8]}>
            {config.addressPrefix.allowedValues.length > 0 ? (
              <div >
                <ValidatedDropDown validatorName='prefix' name={prefix('prefix', namePrefix)} options={[...prefixOptions]} placeholder={t('checkout.address.input.prefix')} defaultValue={address.prefix} />
              </div>
            ) : (
              <input type='text' name={prefix('prefix', namePrefix)} placeholder={t('checkout.address.input.prefix')} defaultValue={address.prefix} />
            )}
          </Row>
        ) : ''}

        <Row mobileNoSplit split={[50, 50]}>
          <ValidatedInput type='text' validatorName='firstname' name={prefix('firstname', namePrefix)} placeholder={t('checkout.address.input.firstname')} defaultValue={address.firstname} validateImmediately/>
          <ValidatedInput type='text' validatorName='lastname' name={prefix('lastname', namePrefix)} placeholder={t('checkout.address.input.lastname')} defaultValue={address.lastname} validateImmediately/>
        </Row>
      </div>
      {typeSelection ? (
        <div>
          <Tabs>
            <Tab icon={Icon.SHIPPING} name={t('checkout.address.home')} onClick={() => setCurrentType(AddressType.HOME)} active={currentType === AddressType.HOME}></Tab>
            <Tab icon={Icon.PARCELBOX} name={t('checkout.address.parcel-box')} onClick={() => setCurrentType(AddressType.PARCEL_BOX)} active={currentType === AddressType.PARCEL_BOX}></Tab>
            <Tab icon={Icon.POSTOFFICE} name={t('checkout.address.post-office')} onClick={() => setCurrentType(AddressType.POST_OFFICE)} active={currentType === AddressType.POST_OFFICE}></Tab>
          </Tabs>


          { currentType === AddressType.HOME ? (
            <>
              <Row>
                <ValidatedInput validatorName='addr1' name={prefix('addr1', namePrefix)} type='text' placeholder={t('checkout.address.input.addr1')} defaultValue={address.addr1} validateImmediately />
                <ValidatedInput validatorName='addr2' name={prefix('addr2', namePrefix)} type='text' placeholder={t('checkout.address.input.addr2')} defaultValue={address.addr2} />
                <Input name={prefix('addressType', namePrefix)} type='hidden' value={currentType} />
              </Row>
            </>
          ) : '' }
          { currentType === AddressType.PARCEL_BOX ? (
            <>
              <Row>
                <ValidatedInput validatorName='addr1' name={prefix('addr1', namePrefix)} type='text' placeholder='Postnummer' defaultValue={address.addr1} validateImmediately />
                <ValidatedInput validatorName='addr2' name={prefix('addr2', namePrefix)} type='text' placeholder='Paketbox' defaultValue={address.addr2} />
                <input name={prefix('addressType', namePrefix)} type='hidden' value={currentType} />
              </Row>
            </>
          ) : '' }
          { currentType === AddressType.POST_OFFICE ? (
            <>
              <Row>
                <ValidatedInput validatorName='addr1' name={prefix('addr1', namePrefix)} type='text' placeholder='Postnummer' defaultValue={address.addr1} validateImmediately />
                <ValidatedInput validatorName='addr2' name={prefix('addr2', namePrefix)} type='text' placeholder='Filiale NR' defaultValue={address.addr2} />
                <input name={prefix('addressType', namePrefix)} type='hidden' value={currentType} />
              </Row>
            </>
          ) : '' }
        </div>
      ) : (
        <Row>

          <ValidatedInput validatorName='addr1' name={prefix('addr1', namePrefix)} type='text' placeholder={t('checkout.address.input.addr1')} defaultValue={address.addr1} validateImmediately/>
          <ValidatedInput validatorName='addr2' name={prefix('addr2', namePrefix)} type='text' placeholder={t('checkout.address.input.addr2')} defaultValue={address.addr2} />
          <Input name={prefix('addressType', namePrefix)} type='hidden' value={currentType} />
        </Row>
      )}


      <Row split={[0.3, 3, 6.5]}>
        {(config.allowedCountries || []).length === 1 ? (
          <>
            <ValidatedInput validatorName='country' name={prefix('country', namePrefix)} type='hidden' defaultValue={config.allowedCountries[0]}/>
            <span className={styles.country}>{config.allowedCountries[0]}</span>
          </>
        ) : ''}

        {(config.allowedCountries || []).length > 1 ? (
          <div>
            <ValidatedDropDown validatorName='country' defaultValue={address.country} name={prefix('country', namePrefix)} options={[...countryOptions]} placeholder={t('checkout.address.input.country')}/>
            <ValidationMessages name='country' />
          </div>
        ) : ''}
        <ValidatedInput validatorName='postcode' name={prefix('postcode', namePrefix)} type='text' placeholder={t('checkout.address.input.postcode')} defaultValue={address.postcode} validateImmediately />
        <ValidatedInput validatorName='city' name={prefix('city', namePrefix)} type='text' placeholder={t('checkout.address.input.city')} defaultValue={address.city} validateImmediately />
      </Row>
    </div>
  );
});





