import { BaseUpdateSession, UpdateSessionResult } from './base-update-session';

type Input = {
  sessionId: string;
  paymentInformation: {code: string};
}

export class SetPaymentMethod extends BaseUpdateSession {
  constructor(public variables: Input) {
    super()
  }
  public readonly query: string = `
    mutation SetPaymentMethod($sessionId: String!, $paymentInformation: PaymentInformationInput!) {
      checkout {
        setPaymentMethod(sessionId: $sessionId, paymentInformation: $paymentInformation) {
          ${this.getPartialSessionResponse()}
        }
      }
    }
  `;
  
    protected transformResponse(responseData: any) {
      return super.transformResponse(responseData, 'setPaymentMethod')
    }
}