import { PropsWithChildren } from 'react';
import { Helmet } from 'react-helmet';
import { ProgressBar } from '../ui/progress/bar';
import { Box } from '../ui/layout/box';
import { Page } from '@lws/lib-checkout';
import { Heading } from '../ui/layout/heading';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ShippingMethod } from './shipping-method';
import styles from './layout.module.scss';

export const PaymentLayout = withTranslation('common')((props: PropsWithChildren & WithTranslation) => {
  const { t } = props;
  return (
    <>
      <Helmet>
        <title>{t('Payment')}</title>
      </Helmet>
      <ProgressBar step={Page.PAYMENT} />
      <Box>
        <Heading title={t('checkout.payment.headline')} level={2} style={{border: 0}}/>
        {props.children}
      </Box>
      <Box className={styles.shipping}>
        <ShippingMethod />
      </Box>
    </>
  );
});
