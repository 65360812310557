import React, { ReactNode } from 'react';
import styles from './heading.module.scss';
import { Icon, IconComponent } from '../icons/icon';

export interface HeadingProps extends React.HTMLAttributes<HTMLDivElement>{
  level?: number
  title: string;
  icon?: Icon
  button?: ReactNode | undefined ;
}

export const Heading = (props: HeadingProps) => {
  const heading = React.createElement(`h${props.level}`, null, props.title);
  const {level, title, icon, ...divprops} = props; // eslint-disable-line @typescript-eslint/no-unused-vars
  const defaultClasses = level === 2 ? `${styles.heading} ${styles.font}` : styles.heading;
  const className = props.className ? `${props.className} ${defaultClasses}` : defaultClasses;

  return (
    <div {...divprops} className={className}>
      {heading}
      <div className={styles.icon}>
        <IconComponent icon={props.icon}/>
      </div>
      {props.button ? props.button : <></>}
    </div>
  );
};
