import { $sessionId } from '../store';
import { client } from '@/client';
import { handleSessionResponse } from '../util/handle-session-response';
import { PlaceSalesOrder } from '@/pre-defined-queries/place-sales-order';

const placeSalesOrder = async(sessionId: string) => {
  const result = await client.exec(new PlaceSalesOrder({sessionId}));
  handleSessionResponse(result);
}

export const useSalesOrder = () => {
  const sessionId = $sessionId.get()!;
  return {
    place: () => placeSalesOrder(sessionId),
  }
}