import { IPaymentMethod } from '../session/payment-method';
import { ITransactionType } from './base';

export interface IPaymentMethodTransaction extends ITransactionType {
  code: string;
  name: string;
}

export enum PaymentAuthorizeStatus {
  PENDING = 'pending',
  REDIRECT = 'redirect',
  SUCCESS = 'success',
  FAILED = 'failed',
}

export interface IAuthorizePaymentTransaction extends ITransactionType {
  code: string;
  status: PaymentAuthorizeStatus;
  redirectUri?: string;
}

export enum PaymentCaptureStatus {
  PENDING = 'pending',
  REDIRECT = 'redirect',
  SUCCESS = 'success',
  FAILED = 'failed',
}

export interface ICapturePaymentTransaction extends ITransactionType {
  code: string;
  name: string;
}