export interface CardIconProps extends React.SVGAttributes<SVGSVGElement>{
}


export const CardIcon = (props: CardIconProps) => {
  return (
    <svg {...props} id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
      <g>
        <path d="M53.18,59.67H19.09c-1.52,0-2.75-1.23-2.75-2.75s1.23-2.75,2.75-2.75h34.09c1.52,0,2.75,1.23,2.75,2.75s-1.23,2.75-2.75,2.75Z"/>
        <path d="M37.58,68.94h-18.49c-1.52,0-2.75-1.23-2.75-2.75s1.23-2.75,2.75-2.75h18.49c1.52,0,2.75,1.23,2.75,2.75s-1.23,2.75-2.75,2.75Z"/>
      </g>
      <path d="M93.56,16.34H6.44c-3.28,0-5.94,2.67-5.94,5.94v55.44c0,3.27,2.67,5.94,5.94,5.94h87.12c3.27,0,5.94-2.67,5.94-5.94V22.28c0-3.27-2.67-5.94-5.94-5.94ZM7.44,21.3h85.12c1.09,0,1.98.89,1.98,1.98v8.9H5.46v-8.9c0-1.09.89-1.98,1.98-1.98ZM92.56,78.7H7.44c-1.09,0-1.98-.89-1.98-1.98v-33.66h89.08v33.66c0,1.09-.89,1.98-1.98,1.98Z"/>
    </svg>
  );
};
