import { PropsWithChildren } from 'react';
import styles from './link.module.scss';

export interface LinkProps extends PropsWithChildren<React.AnchorHTMLAttributes<HTMLAnchorElement>> {}

export const Link = (props: LinkProps) =>  {
  const defaultClasses = styles['link'];
  const className = props.className ? `${props.className} ${defaultClasses}` : defaultClasses;
  return <a {...props} className={className}>{props.children}</a>;
};
