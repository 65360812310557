export interface EditIconProps extends React.SVGAttributes<SVGSVGElement>{
}


export const EditIcon = (props: EditIconProps) => {
  return (
    <svg {...props} id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
      <path d="M15.38,99.38H3.96c-1.68-.6-2.76-1.56-3.29-3.29-.29-2.34-.34-7.79,0-11.42.4-4.31,2.69-8.8,5.51-12L74.31,4.57c14.56-12.27,33.43,6.63,21.18,21.18L27.18,94.07c-3.07,2.68-7.72,4.94-11.81,5.32ZM90.83,8.83c-2.59-2.32-8.41-4.4-12.42-.39-3.23,3.23-10.84,10.89-10.84,10.89l13.19,13.37,11.93-11.93c3.67-4.28.51-9.81-1.86-11.93ZM6.07,84.97c-.21,1.9.16,6.97,0,8.91h9.01c1.44,0,6.63-2.6,7.79-3.54l53.98-53.8-13.41-13.24L9.62,77.08c-1.18,1.44-3.34,6.05-3.55,7.89Z"/>
    </svg>
  );
};
