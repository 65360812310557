import { Button, useRules, useValidationContext, withValidation } from '@lws/react-ui';
import styles from './input.module.scss';
import { ValidationMessages } from './validation-messages';
import { ChangeEventHandler, useState } from 'react';
import { Icon, IconComponent } from '../icons/icon';

export const PasswordToggle = (props: { visible: boolean, setVisible: (visible: boolean) => void }) => {
  return (
    <Button clean className={styles.passwordToggle} onClick={() => props.setVisible(!props.visible)}>
      {props.visible ? <IconComponent icon={Icon.VISIBLE}/> : <IconComponent icon={Icon.INVISIBLE} />}
    </Button>
  );
};

export const Input = (props: React.HTMLProps<HTMLInputElement> & { onChange?: (data: unknown) => void, onStartValidate?: (start: boolean) => void, validatorName?: string, validateImmediately?: boolean }) => {

  const [passwordVisible, setPasswordVisible] = useState(false);

  const onChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    props.onChange?.(e.target.value);
    if (props.validateImmediately) props.onStartValidate?.(true);
  };

  const { domain } = useValidationContext(props.validatorName || '');
  const rules = useRules(domain);
  const required = !!rules?.find(rule => rule.name === 'RequiredRule' || rule.name === 'NonEmptyRule');

  if(!props.placeholder) return <input {...props} />;

  return (
    <div>
      <label className={styles.animatedInput}>
        <input {...props} type={props.type === 'password' ? passwordVisible ? 'text' : 'password' : props.type} onChange={onChange} onBlur={() => { if (!props.validateImmediately) props.onStartValidate?.(true); }} required={required}/>
        <span className={styles.placeholder}>{props.placeholder}{required ? ' *' : ''}</span>
        {props.type === 'password' ? <PasswordToggle visible={passwordVisible} setVisible={setPasswordVisible}/> : <></>}
      </label>
      {props.validatorName ? <ValidationMessages name={props.validatorName} /> : <></>}
    </div>
  );
};

export const ValidatedInput = withValidation(Input);
